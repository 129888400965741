import * as styles from './ErrorBoundary.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import { TextButton } from 'src/components/controls'

// Local storage keys whose values could break the app if corrupted.
const localStorageKeys = [
  'edh-map-selected-trait',
  'edh-map-trait-browser-type',
]

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

export default class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch() {
    // Reset local storage keys that could potentially be corrupted, causing
    // the app to continue to break.
    try {
      for (const key of localStorageKeys) {
        window.localStorage.removeItem(key)
      }
    } catch {
      // no-op
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <div className={styles.error}>
            <p>There was an error rendering the map.</p>

            <p>
              Try{' '}
              <TextButton
                onClick={() => {
                  window.location.reload()
                }}
              >
                refreshing
              </TextButton>{' '}
              the page and <Link to="/about/">contact us</Link> if the problem
              continues.
            </p>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
