import * as styles from './IsolateButton.module.scss'

import React from 'react'

import * as Icon from '../icons'

interface Props {
  children: React.ReactNode
  onClick(): void
}

const IsolateButton: React.FC<Props> = (props) => {
  const { children, onClick } = props

  return (
    <button className={styles.button} onClick={onClick}>
      {children}
      <Icon.Isolate />
    </button>
  )
}

export default IsolateButton
