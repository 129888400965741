import * as styles from './FAQs.module.scss'

import React from 'react'

import Modal from './Modal'
import { FAQContent } from './FAQContent'

interface Props {
  presented: boolean
  dismiss(): void
}

const FAQs: React.FC<Props> = (props) => {
  const { presented, dismiss } = props

  return (
    <Modal presented={presented} dismiss={dismiss}>
      <div className={styles.container}>
        <FAQContent />
      </div>
    </Modal>
  )
}

export default FAQs
