import { useCallback, useMemo } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'

import { DeckTrait, DeckTraits } from './deckTraits'

export const useIsolatedTrait = (deckTraits: DeckTraits) => {
  const traitEncoding: Record<
    string,
    {
      find: (value: string) => number | null
      encode: (value: number) => string | null
    }
  > | null = useMemo(() => {
    if (deckTraits == null) {
      return null
    }

    return {
      commander: {
        find: (value: string) => {
          return deckTraits.commanders.findIndex((row) => row.name === value)
        },
        encode: (value: number) => {
          return deckTraits.commanders[value].name
        },
      },
      partnerCommanders: {
        find: (value: string) => {
          return (
            deckTraits.partnerCommanders?.findIndex(
              (row) => row.name === value,
            ) ?? null
          )
        },
        encode: (value: number) => {
          return deckTraits.partnerCommanders?.[value]?.name ?? null
        },
      },
      colorIdentity: {
        find: (value: string) => {
          return deckTraits.colorIdentities.findIndex(
            (row) => row.symbol.toLowerCase() === value,
          )
        },
        encode: (value: number) => {
          return deckTraits.colorIdentities[value].symbol.toLocaleLowerCase()
        },
      },
      theme: {
        find: (value: string) => {
          return deckTraits.themes.findIndex((row) => row.slug === value)
        },
        encode: (value: number) => {
          return deckTraits.themes[value].slug
        },
      },
      tribe: {
        find: (value: string) => {
          return deckTraits.tribes.findIndex((row) => row.slug === value)
        },
        encode: (value: number) => {
          return deckTraits.tribes[value].slug
        },
      },
    }
  }, [deckTraits])

  const [isolatedTraitParam, setIsolatedTraitParam] = useQueryParam(
    'map',
    StringParam,
  )

  const isolatedTrait: DeckTrait | null = useMemo(() => {
    if (!isolatedTraitParam || !traitEncoding) {
      return null
    }

    const [type, slug] = isolatedTraitParam.split(/-(.+)/)

    if (!type || !slug) {
      return null
    }

    const value = traitEncoding[type].find(slug)

    if (value == null) {
      return null
    }

    return {
      type,
      value,
    } as DeckTrait
  }, [isolatedTraitParam, traitEncoding])

  const setIsolatedTrait = useCallback(
    (trait: DeckTrait | null) => {
      if (traitEncoding == null) {
        throw new Error('Error isolating trait. Encoding not yet loaded.')
      }
      setIsolatedTraitParam(
        trait
          ? `${trait.type}-${traitEncoding[trait.type].encode(trait.value)}`
          : undefined,
      )
    },
    [setIsolatedTraitParam, traitEncoding],
  )

  const result: [DeckTrait | null, (trait: DeckTrait | null) => void] = useMemo(
    () => [isolatedTrait, setIsolatedTrait],
    [isolatedTrait, setIsolatedTrait],
  )

  return result
}
