import { Item } from '../data/data'
import { DeckTrait, DeckTraitType } from '../data/deckTraits'

const traitPredicates: Record<
  DeckTraitType,
  (trait: DeckTrait, item: Item) => boolean
> = {
  commander: (trait: DeckTrait, item: Item) => {
    return item.commanderID === trait.value || item.partnerID === trait.value
  },
  partnerCommanders: () => {
    throw new Error('Unsupported filter type')
  },
  // Currently unused since most partner maps are vey sparse.
  // 'commander-partner': (trait: DeckTrait, item: Item) => {
  //   return item.commanderID === trait.value || item.partnerID === trait.value
  // },
  colorIdentity: (trait: DeckTrait, item: Item) => {
    return item.colorIdentityID === trait.value
  },
  theme: (trait: DeckTrait, item: Item) => {
    return item.themeID === trait.value
  },
  tribe: (trait: DeckTrait, item: Item) => {
    return item.tribeID === trait.value
  },
}

const filterPredicate = (
  selectedItem: Item | null,
  showClusterInfo: boolean,
  selectedTrait: DeckTrait | null,
) => {
  if (selectedTrait) {
    return traitPredicates[selectedTrait.type]
  } else if (selectedItem && showClusterInfo) {
    return (_: DeckTrait | null, item: Item) => {
      return item.clusterID === selectedItem.clusterID
    }
  }
  return null
}

/**
 * Filters decks based on the selected trait and returns a flat vertex array
 * including the position and texture for each point.
 *
 * Returns the vertex array and the number of points matched by the filter.
 * These values are a bit disconnected, but doing them in one pass reduces
 * iterations through a big data set.
 */
export const buildVertexArray = (
  items: Item[],
  selectedItem: Item | null,
  showClusterInfo: boolean,
  selectedTrait: DeckTrait | null,
) => {
  const predicate = filterPredicate(
    selectedItem,
    showClusterInfo,
    selectedTrait,
  )

  if (predicate) {
    const groups = items.reduce(
      // Bin points into two separate groups and then combine them into one
      // array so highlighted points are drawn on top.
      (points: [number[], number[]], item) => {
        const group = predicate(selectedTrait!, item) ? 0 : 1
        points[group].push(item.x, item.y, group / 2)
        return points
      },
      [[] as number[], [] as number[]],
    )
    const points = [...groups[1], ...groups[0]]

    return { points, highlightedPointCount: groups[0].length / 3 }
  }

  const points = items.reduce((points: number[], item) => {
    points.push(item.x, item.y, item.colorIdentityID / 32)
    return points
  }, [] as number[])

  return { points, highlightedPointCount: 0 }
}
