import colorPieAtlas0 from '../images/color-pie-atlas-0.png'
import colorPieAtlas1 from '../images/color-pie-atlas-1.png'
import colorPieAtlas2 from '../images/color-pie-atlas-2.png'
import colorPieAtlas3 from '../images/color-pie-atlas-3.png'
import colorPieAtlas4 from '../images/color-pie-atlas-4.png'
import colorPieAtlas5 from '../images/color-pie-atlas-5.png'
import colorPieAtlas6 from '../images/color-pie-atlas-6.png'
import colorPieAtlas7 from '../images/color-pie-atlas-7.png'
import colorPieAtlas8 from '../images/color-pie-atlas-8.png'
import colorPieAtlas9 from '../images/color-pie-atlas-9.png'
import colorPieAtlas10 from '../images/color-pie-atlas-10.png'
import colorPieAtlas11 from '../images/color-pie-atlas-11.png'

import selectionAtlas0 from '../images/selection-atlas-0.png'
import selectionAtlas1 from '../images/selection-atlas-1.png'
import selectionAtlas2 from '../images/selection-atlas-2.png'
import selectionAtlas3 from '../images/selection-atlas-3.png'
import selectionAtlas4 from '../images/selection-atlas-4.png'
import selectionAtlas5 from '../images/selection-atlas-5.png'
import selectionAtlas6 from '../images/selection-atlas-6.png'
import selectionAtlas7 from '../images/selection-atlas-7.png'

const colorPieMipmapsData = [
  colorPieAtlas0,
  colorPieAtlas1,
  colorPieAtlas2,
  colorPieAtlas3,
  colorPieAtlas4,
  colorPieAtlas5,
  colorPieAtlas6,
  colorPieAtlas7,
  colorPieAtlas8,
  colorPieAtlas9,
  colorPieAtlas10,
  colorPieAtlas11,
]

const selectionMipmapsData = [
  selectionAtlas0,
  selectionAtlas1,
  selectionAtlas2,
  selectionAtlas3,
  selectionAtlas4,
  selectionAtlas5,
  selectionAtlas6,
  selectionAtlas7,
]

/**
 * An array of HTML Image elements for the mipmap levels for the color pie
 * texture atlas.
 */
export const colorPie =
  typeof window !== 'undefined'
    ? colorPieMipmapsData.map((imageData) => {
        const image = new Image()
        image.src = imageData
        return image
      })
    : []

/**
 * An array of HTML Image elements for the mipmap levels for the selection
 * texture atlas.
 */
export const selection =
  typeof window !== 'undefined'
    ? selectionMipmapsData.map((imageData) => {
        const image = new Image()
        image.src = imageData
        return image
      })
    : []

const loadImage = (data: string, callback: () => void): HTMLImageElement => {
  const image = new Image()
  image.src = data
  image.onload = callback
  return image
}

export const loadImages = (
  data: string[],
  callback: (images: HTMLImageElement[]) => void,
) => {
  const images: HTMLImageElement[] = []
  let imagesToLoad = data.length

  const onImageLoad = function () {
    imagesToLoad -= 1
    if (imagesToLoad === 0) {
      callback(images)
    }
  }

  data.map((datum) => loadImage(datum, onImageLoad))
}

/**
 * Bind a texture, defined by an array of mipmap levels, to a WebGL graphics
 * context.
 */
export const bind = (
  context: WebGLRenderingContext,
  mipMapImages: HTMLImageElement[],
) => {
  const texture = context.createTexture()
  context.activeTexture(context.TEXTURE0)
  context.bindTexture(context.TEXTURE_2D, texture)

  for (let i = 0; i < mipMapImages.length; i++) {
    context.texImage2D(
      context.TEXTURE_2D,
      i,
      context.RGBA,
      context.RGBA,
      context.UNSIGNED_BYTE,
      mipMapImages[i],
    )
  }

  context.texParameteri(
    context.TEXTURE_2D,
    context.TEXTURE_MIN_FILTER,
    context.NEAREST_MIPMAP_LINEAR,
  )

  context.texParameteri(
    context.TEXTURE_2D,
    context.TEXTURE_WRAP_S,
    context.CLAMP_TO_EDGE,
  )

  context.texParameteri(
    context.TEXTURE_2D,
    context.TEXTURE_WRAP_T,
    context.CLAMP_TO_EDGE,
  )
}
