import React from 'react'

interface Props {
  color?: string
}

const Search: React.FC<Props> = (props) => {
  const { color } = props

  return (
    <svg x="0px" y="0px" width="25px" height="25px" viewBox="0 0 25 25">
      <g style={{ fill: 'none', stroke: color || '#000000', strokeWidth: 2 }}>
        <line x1="5" y1="5" x2="20" y2="20" />
        <line x1="20" y1="5" x2="5" y2="20" />
      </g>
    </svg>
  )
}

export default Search
