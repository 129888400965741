import * as styles from './Search.module.scss'

import React, { useState, useEffect, useCallback, useRef } from 'react'

import { useDebouncedState } from 'utils/useDebounce'

import { Item, MapData } from '../data/data'
import { DeckTraitType } from '../data/deckTraits'

import { SearchResult, deckSearch, useTraitSearchIndex } from './data'

import ResultRow from './ResultRow'

interface Props {
  data: MapData | null
  selectTrait(type: DeckTraitType, value: number, reset?: boolean): void
  isolateTrait(type: DeckTraitType, value: number): void
  selectAndFocusItem(item: Item): void
}

const Search: React.FC<Props> = (props) => {
  const { data, selectTrait, isolateTrait, selectAndFocusItem } = props

  const searchIndex = useTraitSearchIndex(data?.deckTraits ?? null)

  const resultsFor = useCallback(
    (query: string) => {
      const results = searchIndex.search(query) as any as SearchResult[]

      const deckResult = data != null ? deckSearch(query, data) : null

      if (deckResult) {
        return [deckResult, ...results]
      }

      return results.map((result) => result)
    },
    [data, searchIndex],
  )

  const shouldDebounce = (value: string) => {
    return value.length > 0
  }

  const searchInput = useRef<HTMLInputElement | null>(null)
  const [query, setQuery, debouncedQuery] = useDebouncedState<string>(
    '',
    150,
    shouldDebounce,
  )

  const [selectedResultIndex, setSelectedResultIndex] = useState(0)
  const [searchResults, setSearchResults] = useState<SearchResult[] | null>(
    null,
  )

  const [focused, setFocused] = useState(false)
  const containerElement = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!focused) {
      return
    }

    const onBlur = (event: FocusEvent) => {
      if (!containerElement.current?.contains(event.target as Node)) {
        setFocused(false)
      }
    }

    document.addEventListener('focusin', onBlur)

    return () => {
      document.removeEventListener('focusin', onBlur)
    }
  }, [focused])

  useEffect(() => {
    if (debouncedQuery.length < 3) {
      setSearchResults(null)
    } else {
      setSearchResults(resultsFor(debouncedQuery))
    }
  }, [debouncedQuery, resultsFor])

  useEffect(() => {
    setSelectedResultIndex(0)
  }, [searchResults])

  const onSelectResult = useCallback(
    (result: SearchResult, isolate: boolean) => {
      setQuery('')
      if (result.type === 'item') {
        selectAndFocusItem(result.item)
      } else {
        isolate
          ? isolateTrait(result.type, result.id)
          : selectTrait(result.type, result.id, true)
      }
    },
    [isolateTrait, selectTrait, setQuery, selectAndFocusItem],
  )

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent) => {
      if (searchResults?.length == null || searchResults?.length === 0) {
        return
      }
      if (event.key === 'ArrowUp') {
        setSelectedResultIndex((value) => Math.max(value - 1, 0))
      } else if (event.key === 'ArrowDown') {
        setSelectedResultIndex((value) =>
          Math.min(value + 1, searchResults?.length - 1),
        )
      } else if (event.key === 'Escape') {
        searchInput.current?.blur()
      }
    },
    [searchResults?.length],
  )

  const onInputKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        searchResults?.length != null &&
        searchResults?.length !== 0 &&
        event.key === 'Enter'
      ) {
        onSelectResult(
          searchResults[selectedResultIndex],
          event.getModifierState('Alt'),
        )
      }
    },
    [onSelectResult, searchResults, selectedResultIndex],
  )

  const onInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault()
      }
    },
    [],
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.currentTarget.value)
    },
    [setQuery],
  )

  return (
    <div className={styles.container} ref={containerElement}>
      <input
        type="text"
        className={styles.input}
        placeholder="Search for Commander, Theme, or Deck Link"
        autoComplete="off"
        autoCorrect="false"
        spellCheck="false"
        ref={searchInput}
        value={query}
        onChange={onChange}
        onKeyPress={onInputKeyPress}
        onKeyUp={onKeyUp}
        onKeyDown={onInputKeyDown}
        onFocus={() => setFocused(true)}
        disabled={!data?.deckTraits}
      />

      {focused && searchResults != null && (
        <div className={styles.results}>
          {searchResults.length > 0 ? (
            <>
              {searchResults.map((result, index) => (
                <ResultRow
                  key={`${result.type}-${result.id}`}
                  result={result}
                  index={index}
                  selectedResultIndex={selectedResultIndex}
                  onSelectResult={onSelectResult}
                  onInputKeyPress={onInputKeyPress}
                  setSelectedResultIndex={setSelectedResultIndex}
                />
              ))}
            </>
          ) : (
            <div className={styles.noResults}>No Results Found</div>
          )}
        </div>
      )}
    </div>
  )
}

export default React.memo(Search)
