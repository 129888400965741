import React from 'react'

const EDHRECLogo: React.FC = () => {
  return (
    <svg
      focusable="false"
      width="386"
      height="351"
      viewBox="0 0 386 351"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', display: 'inline' }}
    >
      <g fillRule="nonzero" fill="#1b2433">
        <path d="M192.8 87.7L155.5 105l-27.7 12.8.2 147.2 64.8-30.3 64.8 30.3V117.7l-38-17.6"></path>
        <path d="M126.3 43.2l10.7-5 29.3 62.8-10.8 5M248.8 38.2l10.5 5.1-29.1 62.7-10.6-4.9"></path>
        <circle cx="124.2" cy="24.7" r="19.1"></circle>
        <circle cx="261.8" cy="24.7" r="19.1"></circle>
        <path d="M104.3 177.6l-80.8 37.6v98.6l80.8-37.7M281.7 177.5l80.9 37.7v98.5l-80.9-37.6M.1 350.6L193 260.7l192.8 89.7M142.6 39.9l60.7-33.2-.9 14.8L256.6.7l-60.5 33.1 1.2-13.5"></path>
      </g>
    </svg>
  )
}

export default EDHRECLogo
