import * as textures from './textures'
import { MapProgram } from './buildProgram'

export function bindTextures(
  programInfo: MapProgram,
  textureSet: 'standard' | 'selection',
) {
  const { context } = programInfo

  if (textureSet === 'selection') {
    context.uniform1f(programInfo.uniforms.spriteSheetSize, 4)
    textures.bind(context, textures.selection)
  } else {
    context.uniform1f(programInfo.uniforms.spriteSheetSize, 64)
    textures.bind(context, textures.colorPie)
  }
}
