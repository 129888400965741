import * as styles from './Content.module.scss'

import React, { useMemo, useState } from 'react'

import * as scryfall from 'utils/scryfall'

import ManaCost from 'components/ManaCost'
import CardHover from 'components/cards/CardHover'

import { MapData } from '../data/data'
import { DeckTrait, DeckTraitType, deckTraitDetails } from '../data/deckTraits'
import { useSubmapAttributes } from '../data/submapAttributes'

import Modal from '../Modal'

import * as Icon from '../icons'

import Traits from './Traits'
import PartnersTitle from './PartnersTitle'

interface Props {
  data: MapData
  selectTrait(type: DeckTraitType, value: number): void
  isolatedTrait: DeckTrait
  isolateTrait(type: DeckTraitType, value: number): void
  clearIsolatedTrait(): void
}

const IsolationDetailsContent: React.FC<Props> = (props) => {
  const { data, selectTrait, isolatedTrait, isolateTrait, clearIsolatedTrait } =
    props

  const submapAttributes = useSubmapAttributes(data.deckTraits, isolatedTrait)

  const title = useMemo(() => {
    switch (isolatedTrait.type) {
      case 'commander':
        return data.deckTraits?.commanders?.[isolatedTrait.value]?.name
      case 'partnerCommanders':
        return data.deckTraits?.partnerCommanders?.[isolatedTrait.value]?.name
      case 'colorIdentity':
        return data.deckTraits?.colorIdentities?.[isolatedTrait.value]?.name
      case 'theme':
        return data.deckTraits?.themes?.[isolatedTrait.value]?.name
      case 'tribe':
        return data.deckTraits?.tribes?.[isolatedTrait.value]?.name
    }
  }, [isolatedTrait, data.deckTraits])

  const [showDetails, setShowDetails] = useState(false)

  const artCropImage = useMemo(
    () =>
      isolatedTrait.type === 'commander'
        ? scryfall.imageURL({ cardName: title, version: 'art_crop' })
        : null,
    [isolatedTrait.type, title],
  )

  return (
    <div className={styles.container}>
      <button
        className={styles.mapAttributes}
        onClick={() => setShowDetails(true)}
      >
        {artCropImage != null && title != null && (
          <CardHover card={title}>
            <img src={artCropImage} alt="" className={styles.cardImage} />
          </CardHover>
        )}

        <div className={styles.content}>
          <div className={styles.trait}>
            {deckTraitDetails[isolatedTrait.type]?.name}
          </div>
          <div className={styles.traitValue}>
            {isolatedTrait.type === 'colorIdentity' && (
              <ManaCost>
                {data.deckTraits?.colorIdentities?.[isolatedTrait.value]
                  ?.manaSymbols ?? ''}
              </ManaCost>
            )}
            {isolatedTrait.type === 'commander' ? (
              <CardHover card={title ?? ''}>{title}</CardHover>
            ) : isolatedTrait.type === 'partnerCommanders' ? (
              <PartnersTitle>{title ?? ''}</PartnersTitle>
            ) : (
              title
            )}
          </div>
        </div>
      </button>

      <div className={styles.controls}>
        <button
          className={styles.close}
          onClick={clearIsolatedTrait}
          title="Back to Full Map"
        >
          <Icon.LargeX color="#1b2433" />
        </button>
      </div>

      {submapAttributes && (
        <Modal presented={showDetails} dismiss={() => setShowDetails(false)}>
          <div className={styles.traits}>
            <div className={styles.traitsIntro}>
              <h3 className={styles.traitsHeading}>
                Features of the {title} Map
              </h3>

              <p>
                This map is generated from every {title} deck, using the same
                algorithm as the main map. The locations of decks depicted here
                are independent of their positions on the main map.
              </p>
            </div>

            {submapAttributes != null && (
              <Traits
                data={data}
                traits={submapAttributes}
                selectTrait={selectTrait}
                isolateTrait={isolateTrait}
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}

export default React.memo(IsolationDetailsContent)
