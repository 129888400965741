import * as styles from './DeckDetails.module.scss'

import React, { useMemo, useState } from 'react'

import * as scryfall from 'utils/scryfall'

import { PlainTextButton } from 'components/controls'

import CardHover from 'components/cards/CardHover'
import ManaCost from 'components/ManaCost'

import { MapData, Item } from './data/data'
import { DeckTrait, DeckTraitType } from './data/deckTraits'

import ClusterInfo from './ClusterInfo'
import Modal from './Modal'
import DeckList from './deck-list/Loadable'

import ColorIdentityIcon from './ColorIdentityIcon'
import Card from './info-elements/Card'
import ContentSection from './info-elements/ContentSection'
import InlineIsolateButton from './info-elements/InlineIsolateButton'
import CloseButton from './info-elements/CloseButton'
import ExpandingSection from './info-elements/ExpandingSection'
import ArtistCredit from './info-elements/ArtistCredit'

interface Props {
  data: MapData
  item: Item
  clearSelectedItem(): void
  showClusterInfo: boolean
  setShowClusterInfo(value: boolean): void
  selectTrait(type: DeckTraitType, value: number): void
  isolatedTrait: DeckTrait | null
  isolateTrait(type: DeckTraitType, value: number): void
}

const DeckDetails: React.FC<Props> = (props) => {
  const {
    data,
    item,
    clearSelectedItem,
    showClusterInfo,
    setShowClusterInfo,
    selectTrait,
    isolatedTrait,
    isolateTrait,
  } = props

  const { deckTraits } = data

  const [showDecklist, setShowDecklist] = useState(false)

  const commander = useMemo(
    () => deckTraits?.commanders[item.commanderID],
    [deckTraits?.commanders, item.commanderID],
  )
  const partner = useMemo(
    () =>
      item.partnerID != null ? deckTraits?.commanders[item.partnerID] : null,
    [deckTraits?.commanders, item.partnerID],
  )

  const commanderImage = useMemo(
    () => scryfall.imageURL({ cardName: commander?.name, version: 'art_crop' }),
    [commander],
  )

  const partnerImage = useMemo(
    () =>
      partner
        ? scryfall.imageURL({ cardName: partner.name, version: 'art_crop' })
        : null,
    [partner],
  )

  const colorIdentity = deckTraits?.colorIdentities[item.colorIdentityID]

  if (commander == null || colorIdentity == null) {
    return null
  }

  const { themeID, tribeID } = item

  return (
    <Card>
      <CloseButton onClick={clearSelectedItem} />

      {partner != null && partnerImage != null ? (
        <div className={styles.partnerImages}>
          <img
            className={styles.partnerImage}
            src={commanderImage}
            key={commanderImage}
            alt=""
          />

          <img
            className={styles.partnerImage}
            src={partnerImage}
            key={partnerImage}
            alt=""
          />
        </div>
      ) : (
        <img
          className={styles.commanderImage}
          src={commanderImage}
          key={commanderImage}
          alt=""
        />
      )}

      <ContentSection>
        <div className={styles.type}>Deck</div>
        <div className={styles.title}>
          <ColorIdentityIcon colorIdentity={item.colorIdentityID} />{' '}
          <button
            onClick={() => selectTrait('commander', commander.id)}
            className={styles.titleButton}
          >
            <CardHover card={commander.name}>{commander.name}</CardHover>
          </button>
          {partner && (
            <>
              {' '}
              &{' '}
              <button
                onClick={() => selectTrait('commander', partner.id)}
                className={styles.titleButton}
              >
                <CardHover card={partner.name}>{partner.name}</CardHover>
              </button>
            </>
          )}
        </div>

        <div className={styles.metaData}>
          <button
            onClick={() => setShowDecklist(true)}
            className={styles.decklistButton}
          >
            View Decklist
          </button>

          {item.price && <div>Price: ${item.price.toLocaleString()}</div>}
        </div>
      </ContentSection>

      <ContentSection>
        <div className={styles.link}>
          <PlainTextButton
            onClick={() => {
              selectTrait('commander', commander.id)
            }}
          >
            {commander.name}
          </PlainTextButton>
          <InlineIsolateButton
            onClick={() => isolateTrait('commander', commander.id)}
          />
        </div>

        {partner && (
          <div className={styles.link}>
            <PlainTextButton
              onClick={() => {
                selectTrait('commander', partner.id)
              }}
            >
              {partner.name}
            </PlainTextButton>
            <InlineIsolateButton
              onClick={() => isolateTrait('commander', partner.id)}
            />
          </div>
        )}

        <div className={styles.link}>
          <PlainTextButton
            onClick={() => selectTrait('colorIdentity', item.colorIdentityID)}
          >
            Color:{' '}
            <span className={styles.manaCost}>
              <ManaCost>{colorIdentity.manaSymbols}</ManaCost>
            </span>{' '}
            {colorIdentity.name}
          </PlainTextButton>
          <InlineIsolateButton
            onClick={() => isolateTrait('colorIdentity', item.colorIdentityID)}
          />
        </div>

        {tribeID != null && (
          <div className={styles.link}>
            <PlainTextButton onClick={() => selectTrait('tribe', tribeID)}>
              Tribe: {deckTraits?.tribes?.[tribeID]?.name}
            </PlainTextButton>
            <InlineIsolateButton
              onClick={() => isolateTrait('tribe', tribeID)}
            />
          </div>
        )}

        {themeID != null && (
          <div className={styles.link}>
            <PlainTextButton onClick={() => selectTrait('theme', themeID)}>
              Theme: {deckTraits?.themes?.[themeID]?.name}
            </PlainTextButton>
            <InlineIsolateButton
              onClick={() => isolateTrait('theme', themeID)}
            />
          </div>
        )}
      </ContentSection>

      <div className={styles.artistCreditContainer}>
        <ContentSection>
          <div className={styles.artistCredit}>
            <ArtistCredit
              key={commander.name}
              cardName={commander.name}
              showCardName={partner != null}
            />
            {partner && (
              <ArtistCredit
                key={partner.name}
                cardName={partner.name}
                showCardName
              />
            )}
          </div>
        </ContentSection>
      </div>

      <div className={styles.clusters}>
        <ExpandingSection
          expanded={showClusterInfo}
          onChange={setShowClusterInfo}
          label="Deck&rsquo;s Cluster Details"
        >
          <ClusterInfo
            data={data}
            item={item}
            selectTrait={selectTrait}
            isolatedTrait={isolatedTrait}
            isolateTrait={isolateTrait}
          />
        </ExpandingSection>
      </div>

      <Modal presented={showDecklist} dismiss={() => setShowDecklist(false)}>
        <div className={styles.decklist}>
          <DeckList data={data} deck={item} />
        </div>
      </Modal>
    </Card>
  )
}

export default DeckDetails
