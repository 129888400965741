import { useMemo } from 'react'

import { toInt } from 'utils/typeConversions'
import { useFetchJSON } from 'utils/useFetch'

import { basePath } from './config'
import { parseCardCounts } from './parseCardCounts'

import { MapData, Item } from '../data/data'
import { urlForItem, sourceForItem } from '../data/deckSources'

const batchSize = 100

export interface DeckList {
  id: number
  commander?: string
  partner?: string
  companion?: string
  price: number
  duplicates?: string[]
  list: {
    name: string
    count: number
    ambiguous?: boolean
  }[]
  url: string
  source: string
}

const basicLandTypes = [
  'Plains',
  'Island',
  'Swamp',
  'Mountain',
  'Forest',
  'Wastes',
  'Snow-Covered Plains',
  'Snow-Covered Island',
  'Snow-Covered Swamp',
  'Snow-Covered Mountain',
  'Snow-Covered Forest',
]

export const useDeckList = (data: MapData, deck: Item): DeckList | null => {
  const url = useMemo(() => {
    const batch = Math.floor(deck.id / batchSize) * batchSize

    return `${basePath}/decks/${batch}.json`
  }, [deck.id])

  const { data: responseData } = useFetchJSON<
    Record<
      number,
      {
        deckID: number
        main: string[]
        commanderID: string
        partnerID?: string
        companionID?: string
        price: string
        duplicates?: string[]
      }
    >
  >(url)

  const deckList = useMemo(() => {
    if (!responseData || !responseData[deck.id]) {
      return null
    }

    const deckData = responseData[deck.id]

    const partnerID = toInt(deckData.partnerID)
    const companionID = toInt(deckData.companionID)

    let list = parseCardCounts(deckData.main)

    const ambiguousCards = deckData.duplicates
      ? [...basicLandTypes, ...deckData.duplicates]
      : basicLandTypes

    list = list.map((card) => ({
      ...card,
      ambiguous: ambiguousCards.includes(card.name),
    }))

    const source = sourceForItem(deck)
    const url = urlForItem(deck)

    return {
      id: deck.id,
      commander:
        data.deckTraits?.commanders?.[toInt(deckData.commanderID)!]?.name ??
        undefined,
      partner:
        partnerID != null
          ? data.deckTraits?.commanders?.[partnerID]?.name
          : undefined,
      companion:
        companionID != null
          ? data.deckTraits?.commanders?.[companionID]?.name
          : undefined,
      list,
      price: toInt(deckData.price) ?? 0,
      duplicates: deckData.duplicates,
      source,
      url,
    }
  }, [data.deckTraits?.commanders, deck, responseData])

  return deckList
}
