import * as styles from './OptionTile.module.scss'

import React from 'react'

import * as scryfall from 'utils/scryfall'

import { DeckTrait, DeckTraitType } from '../data/deckTraits'

import InlineIsolateButton from '../info-elements/InlineIsolateButton'

interface Props {
  traitType: DeckTraitType
  id: number
  name: string
  count: number
  image: string
  selectedTrait: DeckTrait | null
  selectTrait(type: DeckTraitType, value: number): void
  isolateTrait(type: DeckTraitType, value: number): void
}

const OptionTile: React.FC<Props> = (props) => {
  const {
    traitType,
    id,
    name,
    count,
    image,
    selectedTrait,
    isolateTrait,
    selectTrait,
  } = props

  return (
    <div className={styles.container}>
      <button
        key={id}
        onClick={() => {
          selectTrait(traitType, id)
        }}
        disabled={
          selectedTrait?.type === 'tribe' && selectedTrait?.value === id
        }
        className={styles.button}
      >
        <img
          src={scryfall.imageURL({ cardName: image, version: 'art_crop' })}
          className={styles.image}
        />

        <div className={styles.content}>
          <div className={styles.title}>{name}</div>
          <div className={styles.count}>{count.toLocaleString()} Decks</div>
        </div>
      </button>

      <div className={styles.isolateButton}>
        <InlineIsolateButton
          onClick={() => {
            isolateTrait(traitType, id)
          }}
        />
      </div>
    </div>
  )
}

export default OptionTile
