// title: Magic: the Gathering Commander Map

import * as styles from './index.module.scss'

import React from 'react'

import { BaseLayout } from 'src/components/layout/Base'
import PageMetaData from 'components/layout/PageMetaData'

import { AccentColor } from 'utils/accent-color/AccentColorContext'

import Map from 'components/maps/edh-map/EDHMap'
import ErrorBoundary from 'components/maps/edh-map/ErrorBoundary'
import { FAQContent } from 'components/maps/edh-map/FAQContent'

interface Props {
  pageContext: {
    url: string
  }
}

const EDHMapPage: React.FC<Props> = () => {
  return (
    <AccentColor color="#4FA09D">
      {/* Included for some text content on the page since the map doesn't render statically. */}
      <div className={styles.description}>
        <FAQContent />
      </div>

      <ErrorBoundary>{typeof window !== 'undefined' && <Map />}</ErrorBoundary>
    </AccentColor>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <BaseLayout>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />

      <PageMetaData
        url={props.pageContext.url}
        metaData={{
          title: 'Magic: the Gathering Commander Map — Lucky Paper',
          description:
            'A map of Magic: The Gathering Commander Decks. The map visualizes the vast format of Commander showing every deck collated by EDHREC in an algorithmically generated map. Explore themes and patterns that emerge and how people build your favorite commanders.',
          ogImageURL:
            'https://luckypaper.co/og-images/Lucky-Paper-Commander-Map.jpg',
          ogType: 'website',
          themeColor: '#ffffff',
        }}
        schema={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: 'Magic: the Gathering Commander Map — Lucky Paper',
          url: props.pageContext.url,
        }}
      />
    </BaseLayout>
  )
}

export default EDHMapPage
