import * as styles from './CommanderDetails.module.scss'

import React, { useMemo } from 'react'
import { compact, kebabCase } from 'lodash'

import * as scryfall from 'utils/scryfall'

import { MapData } from '../data/data'
import { DeckTraitType } from '../data/deckTraits'
import { useCardDetails } from '../data/useCardDetails'

import * as Icon from '../icons'

import Card from '../info-elements/Card'
import ContentSection from '../info-elements/ContentSection'
import CloseButton from '../info-elements/CloseButton'
import IsolateButton from '../info-elements/IsolateButton'
import ExternalLinks from '../info-elements/ExternalLinks'

import Info from './Info'
import PartnerLinks from './PartnerLinks'

interface Props {
  data: MapData
  commanderID: number
  deckCount: number
  selectTrait(type: DeckTraitType, value: number): void
  clearSelectedTrait(): void
  isolateTrait(type: DeckTraitType, value: number): void
}

const CommanderDetails: React.FC<Props> = (props) => {
  const { data, commanderID, deckCount, clearSelectedTrait, isolateTrait } =
    props

  const commander = useMemo(
    () =>
      data?.deckTraits?.commanders
        ? data.deckTraits.commanders[commanderID]
        : null,
    [data?.deckTraits?.commanders, commanderID],
  )

  const partners = useMemo(() => {
    if (commander == null || data.deckTraits?.partnerCommanders == null) {
      return []
    }
    return compact(
      data.deckTraits.partnerCommanders.map((row) => {
        if (row.partners[0] === commander.name) {
          return { ...row, otherPartner: row.partners[1] }
        }
        if (row.partners[1] === commander.name) {
          return { ...row, otherPartner: row.partners[0] }
        }
      }),
    )
  }, [commander, data.deckTraits?.partnerCommanders])

  const commanderImage = useMemo(
    () =>
      commander
        ? scryfall.imageURL({ cardName: commander.name, version: 'art_crop' })
        : null,
    [commander],
  )

  const cardDetails = useCardDetails(commander?.name ?? null)

  const rank = useMemo(() => {
    if (commander?.count == null || data.deckTraits?.commanders == null) {
      return null
    }
    return data.deckTraits.commanders.reduce(
      (result, c) =>
        c.count == null || commander.count! < c.count ? result + 1 : result,
      1,
    )
  }, [commander, data?.deckTraits?.commanders])

  if (!commander) {
    return null
  }

  return (
    <Card>
      <CloseButton onClick={clearSelectedTrait} />

      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src={commanderImage ?? ''}
          key={commanderImage}
          alt=""
        />
        {cardDetails?.banned && <div className={styles.banned}>Banned</div>}
      </div>

      <ContentSection>
        <Info
          type="Commander"
          title={commander.name}
          deckCount={deckCount}
          cardName={commander.name}
          edhrecRank={rank}
        />

        <IsolateButton onClick={() => isolateTrait('commander', commanderID)}>
          View Map of Decks
        </IsolateButton>
      </ContentSection>

      {partners.length > 0 && (
        <ContentSection>
          <PartnerLinks partners={partners} isolateTrait={isolateTrait} />
        </ContentSection>
      )}

      <ContentSection>
        {cardDetails?.artist && (
          <div className={styles.artistCredit}>
            <Icon.ArtCredit /> Illustration by {cardDetails.artist}
          </div>
        )}

        <ExternalLinks
          links={[
            {
              source: 'Scryfall',
              link: `https://scryfall.com/search?q=%21%22${commander.name}%22`,
              text: 'View on Scryfall',
            },
            {
              source: 'EDHREC',
              link: `https://edhrec.com/commanders/${kebabCase(
                commander.name,
              )}`,
              text: 'View on EDHREC',
            },
          ]}
        />
      </ContentSection>
    </Card>
  )
}

export default CommanderDetails
