import * as styles from './TribeDetails.module.scss'

import React, { useMemo } from 'react'

import * as scryfall from 'utils/scryfall'

import { MapData } from '../data/data'
import { DeckTraitType } from '../data/deckTraits'
import { singularTribeName } from '../data/inflectTribe'

import Card from '../info-elements/Card'
import ContentSection from '../info-elements/ContentSection'
import CloseButton from '../info-elements/CloseButton'
import IsolateButton from '../info-elements/IsolateButton'
import ArtistCredit from '../info-elements/ArtistCredit'
import ExternalLinks from '../info-elements/ExternalLinks'

import Info from './Info'

interface Props {
  data: MapData
  tribeID: number
  deckCount: number
  clearSelectedTrait(): void
  isolateTrait(type: DeckTraitType, value: number): void
}

const TribeDetails: React.FC<Props> = (props) => {
  const { data, tribeID, deckCount, clearSelectedTrait, isolateTrait } = props

  const tribe = useMemo(
    () => (data?.deckTraits?.tribes ? data.deckTraits.tribes[tribeID] : null),
    [tribeID, data?.deckTraits?.tribes],
  )

  const image = useMemo(
    () =>
      tribe
        ? scryfall.imageURL({ cardName: tribe.image, version: 'art_crop' })
        : null,
    [tribe],
  )

  if (!tribe) {
    return null
  }

  const singular = singularTribeName(tribe.name)

  return (
    <Card>
      <CloseButton onClick={clearSelectedTrait} />

      <div className={styles.images}>
        <img className={styles.image} src={image ?? ''} key={image} />
      </div>

      <ContentSection>
        <Info type="Tribe" title={tribe.name} deckCount={deckCount} />

        <IsolateButton onClick={() => isolateTrait('tribe', tribeID)}>
          View Map of {singular} Decks
        </IsolateButton>
      </ContentSection>

      <ContentSection>
        <div className={styles.artistCredit}>
          <ArtistCredit cardName={tribe.image} showCardName />
        </div>

        <ExternalLinks
          links={[
            {
              source: 'EDHREC',
              link: `https://edhrec.com/tribes/${tribe.slug}`,
              text: 'View Tribe on EDHREC',
            },
            {
              source: 'Scryfall',
              link: `https://scryfall.com/search?q=t%3A${singular}`,
              text: 'View Tribe on Scryfall',
            },
          ]}
        />
      </ContentSection>
    </Card>
  )
}

export default TribeDetails
