import { useEffect, useMemo, useRef, useState } from 'react'

import { useFetchCSV } from 'utils/useCSV'
import { toInt, toFloat } from 'utils/typeConversions'

import { basePath } from './config'

import { DeckTrait, DeckTraits } from './deckTraits'
import { useIsolationPath } from './isolationPath'

export interface Item {
  id: number
  clusterID: number
  colorIdentityID: number
  commanderID: number
  partnerID: number | null
  path: string
  price: number
  siteID: 0 | 1 | 2 | 3 | 4
  themeID: number | null
  tribeID: number | null
  x: number
  y: number
}

const transformMapRow = (row: Record<string, string>, id: number): Item => {
  return {
    id: row['deckID'] ? parseInt(row['deckID']) : id,
    clusterID: toInt(row['clusterID'])!,
    colorIdentityID: toInt(row['colorIdentityID'])!,
    commanderID: toInt(row['commanderID'])!,
    partnerID: toInt(row['partnerID']),
    path: row['path'],
    price: toInt(row['price'])!,
    siteID: toInt(row['siteID']) as 0 | 1 | 2 | 3 | 4,
    themeID: toInt(row['themeID']),
    tribeID: toInt(row['tribeID']),
    x: toFloat(row['x'])!,
    y: toFloat(row['y'])!,
  }
}

const emptyDataURI = 'data:,'

// A constant empty set is important for memoization
const emptyDataSet: Item[] = []

const mapURL = `${basePath}/edh-map.csv`

/**
 * Downloads data used for points on the map.
 */
export const useMapPoints = (): Item[] => {
  const items = useFetchCSV<Item>(mapURL, transformMapRow as any)

  useEffect(() => {
    if (items && items.length > 0) {
      // eslint-disable-next-line no-console
      console.log(`Loaded ${items.length.toLocaleString()} decks!`)
    }
  }, [items])

  return items || emptyDataSet
}

/**
 * Downloads data used for a submap in 'isolation mode'.
 */
export const useIsolatedMapPoints = (
  traits: DeckTraits | null,
  selectedTrait: DeckTrait | null,
): { items: Item[]; loading: boolean } => {
  const path = useIsolationPath(traits, selectedTrait)

  const [loading, setLoading] = useState(false)
  const prevSelectedTrait = useRef<DeckTrait | null>(null)
  const prevItems = useRef<Item[] | null>(null)

  const url = useMemo(() => {
    return path ? `${basePath}/submaps/${path}/edh-submap.csv` : emptyDataURI
  }, [path])

  const items = useFetchCSV<Item>(url, transformMapRow)

  useEffect(() => {
    setLoading(true)
    prevSelectedTrait.current = selectedTrait
  }, [selectedTrait])

  useEffect(() => {
    setLoading(false)
    prevItems.current = items
  }, [items])

  return { items: items || emptyDataSet, loading }
}
