import React from 'react'

interface Props {
  color?: string
}

const Search: React.FC<Props> = (props) => {
  const { color } = props

  return (
    <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20">
      <g style={{ fill: 'none', stroke: color || '#000000', strokeWidth: 2 }}>
        <line x1="5" y1="5" x2="15" y2="15" />
        <line x1="15" y1="5" x2="5" y2="15" />
      </g>
    </svg>
  )
}

export default Search
