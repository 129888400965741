import * as styles from './ColorIdentityDetails.module.scss'

import React from 'react'

import ManaCost from 'components/ManaCost'

import { MapData } from '../data/data'
import { DeckTraitType } from '../data/deckTraits'

import Card from '../info-elements/Card'
import ContentSection from '../info-elements/ContentSection'
import CloseButton from '../info-elements/CloseButton'
import IsolateButton from '../info-elements/IsolateButton'
import ExternalLinks from '../info-elements/ExternalLinks'

import Info from './Info'

interface Props {
  data: MapData
  colorIdentityID: number
  deckCount: number
  clearSelectedTrait(): void
  isolateTrait(type: DeckTraitType, value: number): void
}

const ColorIdentityDetails: React.FC<Props> = (props) => {
  const { data, colorIdentityID, deckCount, clearSelectedTrait, isolateTrait } =
    props

  const colorIdentity = data.deckTraits?.colorIdentities[colorIdentityID]

  if (!colorIdentity) {
    return null
  }

  return (
    <Card>
      <CloseButton onClick={clearSelectedTrait} />

      <div className={styles.colors}>
        {colorIdentity.colors.map((color, index) => (
          <div key={index} style={{ backgroundColor: color }} />
        ))}
      </div>

      <ContentSection>
        <Info
          type="Color Identity"
          title={
            <>
              <ManaCost>{colorIdentity.manaSymbols}</ManaCost>{' '}
              {colorIdentity.name}
            </>
          }
          deckCount={deckCount}
        />

        <IsolateButton
          onClick={() => isolateTrait('colorIdentity', colorIdentityID)}
        >
          View Map of {colorIdentity.name} Decks
        </IsolateButton>
      </ContentSection>

      <ContentSection>
        <ExternalLinks
          links={[
            {
              source: 'Scryfall',
              link: `https://scryfall.com/search?q=is%3Acommander+ci%3D${colorIdentity.symbol}`,
              text: 'View Commanders on Scryfall',
            },
            {
              source: 'EDHREC',
              link: `https://edhrec.com/commanders/${colorIdentity.edhrecSlug}`,
              text: 'View on EDHREC',
            },
          ]}
        />
      </ContentSection>
    </Card>
  )
}

export default ColorIdentityDetails
