import { useMemo } from 'react'

import { useFetchCSV } from 'utils/useCSV'
import { toInt } from 'utils/typeConversions'

import { basePath } from './config'

import { colorIdentities as colorIdentityDetails } from './colorIdentities'

export type DeckTraitType =
  | 'colorIdentity'
  | 'commander'
  | 'partnerCommanders'
  | 'theme'
  | 'tribe'

export interface DeckTrait {
  type: DeckTraitType
  value: number
}

/**
 * Display attributes for the different types of "deck traits".
 */
export const deckTraitDetails: Record<
  DeckTraitType,
  { slug: DeckTraitType; name: string; namePlural: string }
> = {
  colorIdentity: {
    slug: 'colorIdentity',
    name: 'Color Identity',
    namePlural: 'Color Identities',
  },
  commander: {
    slug: 'commander',
    name: 'Commander',
    namePlural: 'Commanders',
  },
  partnerCommanders: {
    slug: 'partnerCommanders',
    name: 'Partners',
    namePlural: 'Partners',
  },
  theme: {
    slug: 'theme',
    name: 'Theme',
    namePlural: 'Themes',
  },
  tribe: {
    slug: 'tribe',
    name: 'Tribe',
    namePlural: 'Tribes',
  },
}

// Loading CSVs

const transformColorIdentityRow = (row: Record<string, string>, id: number) => {
  return {
    id,
    name: row['name'],
    count: toInt(row['count']),
  }
}

const transformCommandersRow = (row: Record<string, string>, id: number) => {
  return {
    id,
    name: row['name'],
    count: toInt(row['count']),
  }
}

const transformPartnerCommandersRow = (row: Record<string, string>) => {
  return {
    name: row['name'],
    partners: row['name'].split(' + ') as [string, string],
    count: toInt(row['count']),
  }
}

const transformThemesRow = (row: Record<string, string>, id: number) => {
  return {
    id,
    name: row['name'],
    slug: row['slug'],
    image: row['image'],
    count: toInt(row['count']),
  }
}

const transformTribesRow = (row: Record<string, string>, id: number) => {
  return {
    id,
    name: row['name'],
    slug: row['slug'],
    image: row['image'],
    count: toInt(row['count']),
  }
}

export const useDeckTraits = () => {
  const colorIdentitiesData = useFetchCSV<
    ReturnType<typeof transformColorIdentityRow>
  >(`${basePath}/traits/colorIdentityID.csv`, transformColorIdentityRow as any)

  const colorIdentities = useMemo(() => {
    return colorIdentitiesData?.map((row, index) => {
      return {
        ...colorIdentityDetails[index],
        id: row.id,
        count: row.count,
      }
    })
  }, [colorIdentitiesData])

  const commanders = useFetchCSV<ReturnType<typeof transformCommandersRow>>(
    `${basePath}/traits/commanderID.csv`,
    transformCommandersRow as any,
  )

  const allPartnerCommanders = useFetchCSV<
    ReturnType<typeof transformPartnerCommandersRow>
  >(
    `${basePath}/traits/commander-partnerID.csv`,
    transformPartnerCommandersRow as any,
  )

  const partnerCommanders = useMemo(() => {
    if (!allPartnerCommanders) {
      return null
    }

    // Filter combinations with few decks. Reassign IDs which aren't referenced
    // in any other data set but used internally.
    return allPartnerCommanders
      .filter((row) => row.count != null && row.count > 200)
      .map((row, index) => ({ ...row, id: index }))
  }, [allPartnerCommanders])

  const themes = useFetchCSV<ReturnType<typeof transformThemesRow>>(
    `${basePath}/traits/themeID.csv`,
    transformThemesRow as any,
  )

  const tribes = useFetchCSV<ReturnType<typeof transformTribesRow>>(
    `${basePath}/traits/tribeID.csv`,
    transformTribesRow as any,
  )

  const data = useMemo(() => {
    return colorIdentities && commanders && themes && tribes
      ? {
          colorIdentities,
          commanders,
          partnerCommanders,
          themes,
          tribes,
        }
      : null
  }, [colorIdentities, commanders, partnerCommanders, themes, tribes])

  return data
}

export type DeckTraits = ReturnType<typeof useDeckTraits>
