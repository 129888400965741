import { glsl } from 'components/maps/shared/glsl'

export const vertexShaderSource = glsl`
  precision mediump float;

  uniform vec4 transform;
  uniform float pointSize;
  
  attribute vec2 position;
  attribute float texture;

  varying vec2 vTextureCoord;

  void main() {
    gl_Position = vec4(
      position.xy * transform.zw + transform.xy,
      0.0,
      1.0
    );

    gl_PointSize = pointSize;

    vTextureCoord = vec2(texture, 0.0);
  }
`

export const fragmentShaderSource = glsl`
  precision mediump float;

  uniform sampler2D spriteTexture;
  uniform float spriteSheetSize;

  varying vec2 vTextureCoord;

  void main() {
    vec2 uv = vec2(gl_PointCoord.x / spriteSheetSize, gl_PointCoord.y) + vTextureCoord;
    gl_FragColor = texture2D(spriteTexture, uv);
  }
`
