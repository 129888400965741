import { useEffect, useMemo } from 'react'
import * as JSSearch from 'js-search'

import { DeckTraits, DeckTraitType } from '../data/deckTraits'

const addTypeAttribute = <T>(values: T[], type: string) => {
  return values.map((v) => ({ ...v, type }))
}

export interface TraitResult {
  type: DeckTraitType
  id: number
  name: string
  count: number
  image?: string
  manaSymbols?: string
}

export function useTraitSearchIndex(deckTraits: DeckTraits | null) {
  const searchIndex = useMemo(() => {
    const searchIndex = new JSSearch.Search('id')
    searchIndex.addIndex('name')
    searchIndex.addIndex('searchAliases')

    return searchIndex
  }, [])

  useEffect(() => {
    if (deckTraits) {
      searchIndex.addDocuments(
        addTypeAttribute(deckTraits.commanders, 'commander'),
      )
      searchIndex.addDocuments(
        addTypeAttribute(deckTraits.colorIdentities, 'colorIdentity'),
      )
      searchIndex.addDocuments(addTypeAttribute(deckTraits.themes, 'theme'))
      searchIndex.addDocuments(addTypeAttribute(deckTraits.tribes, 'tribe'))
    }
  }, [searchIndex, deckTraits])

  return searchIndex
}
