import { compact } from 'lodash'

import { toInt } from 'utils/typeConversions'

const cardPattern = /^ *(?<count>\d+)?x? *(?<card>.+)$/

export const parseCardCounts = (rows: string[]) => {
  return compact(
    rows.map((row) => {
      const result = row.match(cardPattern)

      if (!result?.groups?.card) {
        return null
      }

      return {
        name: result?.groups?.card,
        count: toInt(result?.groups?.count) ?? 1,
      }
    }),
  )
}
