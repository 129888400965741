import React from 'react'

import CardHover from 'components/cards/CardHover'

import { useCardDetails } from '../data/useCardDetails'

import * as Icon from '../icons'

interface Props {
  cardName: string
  showCardName?: boolean
}

const ArtistCredit: React.FC<Props> = (props) => {
  const { cardName, showCardName = false } = props

  const cardDetails = useCardDetails(cardName)

  if (!cardDetails?.artist) {
    return null
  }

  return (
    <div>
      <Icon.ArtCredit />{' '}
      {showCardName && (
        <>
          <CardHover card={cardName}>{cardName}</CardHover>{' '}
        </>
      )}
      Illustration by {cardDetails.artist}
    </div>
  )
}

export default ArtistCredit
