import * as styles from './ColorTile.module.scss'

import React, { useCallback, useRef } from 'react'
import classNames from 'classnames'

import ManaCost from 'components/ManaCost'

import * as Icon from '../icons'
import { DeckTrait, DeckTraitType } from '../data/deckTraits'
import { ColorIdentity } from '../data/colorIdentities'

interface Props {
  colorIdentity: {
    id: number
    count: number
  } & ColorIdentity
  selectedTrait: DeckTrait | null
  selectTrait(type: DeckTraitType, value: number): void
  isolateTrait(type: DeckTraitType, value: number): void
}

const ColorTile: React.FC<Props> = (props) => {
  const { colorIdentity, selectedTrait, selectTrait, isolateTrait } = props

  const isolateIconRef = useRef<HTMLDivElement | null>(null)

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (isolateIconRef.current?.contains(event.target as HTMLElement)) {
        isolateTrait('colorIdentity', colorIdentity.id)
      } else {
        selectTrait('colorIdentity', colorIdentity.id)
      }
    },
    [colorIdentity.id, selectTrait, isolateTrait],
  )

  return (
    <button
      key={colorIdentity.symbol}
      onClick={onClick}
      disabled={
        selectedTrait?.type === 'colorIdentity' &&
        selectedTrait?.value === colorIdentity.id
      }
      className={styles.container}
    >
      <div
        className={classNames(
          styles.symbol,
          `length-${colorIdentity.symbol.length}`,
        )}
      >
        <ManaCost>{colorIdentity.manaSymbols}</ManaCost>
      </div>

      <div className={styles.name}>
        {colorIdentity.name}
        <div className={styles.isolateIcon} ref={isolateIconRef}>
          <Icon.Isolate />
        </div>
      </div>

      <div className={styles.count}>
        {colorIdentity.count.toLocaleString()} Decks
      </div>
    </button>
  )
}

export default ColorTile
