import React from 'react'

const Isolate: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <g style={{ fill: 'none', stroke: 'currentcolor', strokeWidth: '1.5px' }}>
        <polyline points="6.72 4.29 1.6 4.29 1.6 14.37 11.69 14.37 11.69 9.28" />
        <rect x="8.59" y="1.49" width="5.92" height="5.92" />
      </g>

      <g fill="currentColor">
        <rect x="11.18" y="3" width="1.82" height="1.82" />
      </g>
    </svg>
  )
}

export default Isolate
