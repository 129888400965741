import * as styles from './Attributions.module.scss'

import React from 'react'

import { date } from './data/config'

const Attributions: React.FC = () => {
  return (
    <div className={styles.container}>
      Data Last Updated {date}
      . &copy; Lucky Paper.
      <br />
      Data courtesy of{' '}
      <a
        href="https://edhrec.com"
        className={styles.link}
        target="_blank"
        rel="noreferrer"
      >
        EDHREC
      </a>
      . Card images courtesy of{' '}
      <a
        className={styles.link}
        href="https://scryfall.com"
        target="_blank"
        rel="noreferrer"
      >
        Scryfall
      </a>
      .
    </div>
  )
}

export default React.memo(Attributions)
