import { Item } from './data'

// To reduce file size in the map, URLs are trimmed down to the bare minimum
// along with an ID for which of the sources a deck is from. This provides
// functions for translating between source id and "path" and complete URLs.

export const sources = [
  {
    name: 'Aetherhub',
    urlTemplate: (path: string) => `http://aetherhub.com/Deck/Public/${path}`,
    pattern: /aetherhub.com\/Deck\/(Public\/|.*?-)?(?<path>\d+)($|\/)/,
  },
  {
    name: 'Archidekt',
    urlTemplate: (path: string) => `https://archidekt.com/decks/${path}`,
    pattern: /archidekt.com\/decks\/(?<path>.*)/,
  },
  {
    name: 'Deckstats',
    urlTemplate: (path: string) => `https://deckstats.net/decks/${path}-`,
    pattern: /deckstats.net\/decks\/(?<path>\d+\/\d+)-/,
  },
  {
    name: 'Moxfield',
    urlTemplate: (path: string) => `https://moxfield.com/decks/${path}`,
    pattern: /moxfield.com\/decks\/(?<path>.*)/,
  },
  {
    name: 'MTG Goldfish',
    urlTemplate: (path: string) => `http://www.mtggoldfish.com/deck/${path}`,
    pattern: /mtggoldfish.com\/deck\/(?<path>.*?)($|#)/,
  },
]

export const urlForItem = (item: Item) => {
  return sources[item.siteID].urlTemplate(item.path)
}

export const sourceForItem = (item: Item) => {
  return sources[item.siteID].name
}

export const attributesForURL = (url: string) => {
  let path: string | null = null

  const source = sources.findIndex((source) => {
    const match = url.trim().match(source.pattern)
    if (match) {
      path = match.groups!.path
      return true
    }

    return false
  })

  return source === -1 || path == null ? null : { source, path }
}
