import * as styles from './Traits.module.scss'

import React, { useState } from 'react'

import { PlainTextButton } from 'components/controls'

import CardHover from 'components/cards/CardHover'
import ManaCost from 'components/ManaCost'

import { MapData } from '../data/data'
import { DeckTraitType } from '../data/deckTraits'
import { singleCardThemes } from '../data/singleCardThemes'

import Tooltip from '../Tooltip'
import InlineIsolateButton from '../info-elements/InlineIsolateButton'

interface Props {
  data: MapData
  traits: {
    definingCards: {
      name: string
      playRate: number
      synergy: number
    }[]
    commanders?: {
      id: number
      partnerID?: number
      percent: number
    }[]
    colorIdentities?: {
      id: number
      percent: number
    }[]
    themes?: {
      id: number
      percent: number
    }[]
    tribes?: {
      id: number
      percent: number
    }[]
  }
  selectTrait(type: DeckTraitType, value: number): void
  isolateTrait(type: DeckTraitType, value: number): void
}

const Traits: React.FC<Props> = (props) => {
  const { data, traits, selectTrait, isolateTrait } = props

  const [showMoreCards, setShowMoreCards] = useState(false)

  if (data.deckTraits == null) {
    return null
  }

  const { commanders, colorIdentities, themes, tribes } = data.deckTraits

  const cards = traits.definingCards.slice(0, showMoreCards ? 100 : 20)

  const hasMoreCards = traits.definingCards.length > 20

  const hasSynergy = cards[0]?.synergy != null

  return (
    <div className={styles.container}>
      <div>
        <h3 className={styles.heading}>Defining Cards</h3>
        {cards.map((card, index) => (
          <div key={index} className={styles.item}>
            <div>
              <CardHover card={card.name}>{card.name}</CardHover>
            </div>

            <div className={styles.number}>
              {hasSynergy ? (
                <Tooltip
                  content={`Play rate in this map compared to global rate (${Math.round(
                    card.playRate * 100,
                  )}%).`}
                >
                  {Math.round(card.synergy * 100)}%
                </Tooltip>
              ) : (
                <Tooltip content="Play rate across all decks in this map.">
                  {Math.round(card.playRate * 100)}%
                </Tooltip>
              )}
            </div>
          </div>
        ))}

        {hasMoreCards && (
          <div className={styles.showMoreButton}>
            <PlainTextButton
              onClick={() => setShowMoreCards((value) => !value)}
            >
              {showMoreCards ? 'Show Less' : 'Show More'}
            </PlainTextButton>
          </div>
        )}
      </div>

      {traits.commanders && (
        <div>
          <h3 className={styles.heading}>Top Commanders</h3>
          {traits.commanders.map((commander) => {
            const { partnerID } = commander

            return (
              <div
                key={`${commander.id}-${commander.partnerID || ''}`}
                className={styles.item}
              >
                <div>
                  <div>
                    <PlainTextButton
                      onClick={() => selectTrait('commander', commander.id)}
                    >
                      <CardHover card={commanders[commander.id]?.name}>
                        {commanders[commander.id]?.name}
                      </CardHover>
                    </PlainTextButton>
                    <InlineIsolateButton
                      onClick={() => isolateTrait('commander', commander.id)}
                    />
                  </div>

                  {partnerID && (
                    <div>
                      &amp;{' '}
                      <PlainTextButton
                        onClick={() => selectTrait('commander', partnerID)}
                      >
                        <CardHover card={commanders[partnerID]?.name}>
                          {commanders[partnerID]?.name}
                        </CardHover>
                      </PlainTextButton>
                      <InlineIsolateButton
                        onClick={() => isolateTrait('commander', partnerID)}
                      />
                    </div>
                  )}
                </div>

                <div className={styles.number}>{commander.percent}%</div>
              </div>
            )
          })}
        </div>
      )}

      {traits.colorIdentities && (
        <div>
          <h3 className={styles.heading}>Top Colors</h3>
          {traits.colorIdentities.map((colorIdentity, index) => (
            <div key={index} className={styles.item}>
              <div>
                <PlainTextButton
                  onClick={() => selectTrait('colorIdentity', colorIdentity.id)}
                >
                  <span className={styles.manaCost}>
                    <ManaCost>
                      {colorIdentities[colorIdentity.id].manaSymbols}
                    </ManaCost>
                  </span>{' '}
                  {colorIdentities[colorIdentity.id].name}
                </PlainTextButton>
                <InlineIsolateButton
                  onClick={() =>
                    isolateTrait('colorIdentity', colorIdentity.id)
                  }
                />
              </div>

              <div className={styles.number}>{colorIdentity.percent}%</div>
            </div>
          ))}
        </div>
      )}

      {traits.themes && (
        <div>
          <h3 className={styles.heading}>Themes</h3>
          {traits.themes.map((theme, index) => (
            <div key={index} className={styles.item}>
              <div>
                <PlainTextButton onClick={() => selectTrait('theme', theme.id)}>
                  {singleCardThemes.includes(themes[theme.id].slug) ? (
                    <CardHover card={themes[theme.id].image}>
                      {themes[theme.id].name}
                    </CardHover>
                  ) : (
                    themes[theme.id].name
                  )}
                </PlainTextButton>
                <InlineIsolateButton
                  onClick={() => isolateTrait('theme', theme.id)}
                />
              </div>

              <div className={styles.number}>{theme.percent}%</div>
            </div>
          ))}
        </div>
      )}

      {traits.tribes && (
        <div>
          <h3 className={styles.heading}>Tribes</h3>
          {traits.tribes.map((tribe, index) => (
            <div key={index} className={styles.item}>
              <div>
                <PlainTextButton onClick={() => selectTrait('tribe', tribe.id)}>
                  {tribes[tribe.id].name}
                </PlainTextButton>
                <InlineIsolateButton
                  onClick={() => isolateTrait('tribe', tribe.id)}
                />
              </div>

              <div className={styles.number}>{tribe.percent}%</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Traits
