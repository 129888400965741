import { useEffect, useState } from 'react'

import * as scryfall from 'utils/scryfall'

export const useCardDetails = (cardName: string | null) => {
  const [cardAttributes, setCardAttributes] = useState<{
    artist: string
    manaCost: string
    banned: boolean
  } | null>(null)

  useEffect(() => {
    if (cardName == null) {
      return
    }
    scryfall.fetchCard({ name: cardName }).then((result) => {
      if (result != null && result.object !== 'error') {
        setCardAttributes({
          artist: result.artist,
          manaCost: result.card_faces
            ? result.card_faces[0].mana_cost
            : result.mana_cost,
          banned: result.legalities.commander === 'banned',
        })
      }
    })
  }, [cardName])

  return cardAttributes
}
