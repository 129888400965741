import * as d3 from 'd3'

import { useSearchTree, Point } from 'components/maps/shared/useSearchTree'

/**
 * Generates two search trees specifically to keep the cached primary tree for
 * the main EDH map to improve performance when navigating back. This is a
 * little clunky and may be done better by not using hooks for all this logic.
 */

export const useSearchTrees = <T extends Point>(
  primaryItems: T[],
  secondaryItems: T[],
  useSecondary: boolean,
  width: number,
  height: number,
  zoomTransform: d3.ZoomTransform,
  extent: [[number, number], [number, number]],
) => {
  const primaryMapSearchTree = useSearchTree(
    primaryItems,
    width,
    height,
    zoomTransform,
    extent,
  )
  const isolationMapSearchTree = useSearchTree(
    secondaryItems,
    width,
    height,
    zoomTransform,
    extent,
  )
  return useSecondary ? isolationMapSearchTree : primaryMapSearchTree
}
