import * as styles from './Block.module.scss'

import React from 'react'

import { MapData } from '../data/data'
import { DeckTrait, DeckTraitType } from '../data/deckTraits'

import Card from '../info-elements/Card'
import Content from './Content'

interface Props {
  data: MapData
  selectTrait(type: DeckTraitType, value: number): void
  isolatedTrait: DeckTrait
  isolateTrait(type: DeckTraitType, value: number): void
  clearIsolatedTrait(): void
}

const IsolationDetails: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <Card>
        <Content {...props} />
      </Card>
    </div>
  )
}

export default React.memo(IsolationDetails)
