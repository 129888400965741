import * as styles from './Decklist.module.scss'

import React, { useEffect, useMemo, useState } from 'react'
import { compact } from 'lodash'

import * as scryfall from 'utils/scryfall'
import * as mtg from 'utils/mtg'

import { CopyButton } from 'components/forms'

import LoadingIndicator from 'components/loading/Indicator'

import CardHover from 'components/cards/CardHover'
import CardImage from 'components/cards/CardImage'

import { DeckList } from '../data/useDeckList'

interface Props {
  deck: DeckList
}

const Decklist: React.FC<Props> = (props) => {
  const { deck } = props

  const [groupedCards, setGroupedCards] = useState<
    | {
        type: string
        cards: (mtg.SimplifiedCard & { count: number; ambiguous?: boolean })[]
        length: number
      }[]
    | null
  >(null)

  useEffect(() => {
    scryfall
      .fetchCollection(deck.list.map((r) => ({ name: r.name })))
      .then((result) => {
        const cards = mtg.simplifyCards(result.cards)

        setGroupedCards(
          mtg.groupCardsByType(
            deck.list.map((row) => ({
              ...row,
              ...cards.find(
                (c) => c.name.split(' // ')[0] === row.name.split(' // ')[0],
              )!,
            })),
          ),
        )
      })
  }, [deck.list])

  const copyText = useMemo(() => {
    return compact([
      deck.commander,
      deck.partner,
      deck.companion,
      ...deck.list.map((card) => `${card.count} ${card.name}`),
    ]).join('\n')
  }, [deck])

  return (
    <div>
      <div className={styles.heading}>
        {deck.commander} {deck.partner && <> &amp; {deck.partner}</>}
      </div>

      <div className={styles.columns}>
        <div className={styles.meta}>
          <div>
            {deck.commander != null && (
              <div className={styles.commander}>
                <CardImage>{deck.commander}</CardImage>
              </div>
            )}

            {deck.partner != null && (
              <div className={styles.commander}>
                <CardImage>{deck.partner}</CardImage>
              </div>
            )}

            {deck.companion != null && (
              <div className={styles.commander}>
                <CardImage>{deck.companion}</CardImage>
              </div>
            )}
          </div>

          <a
            href={deck.url}
            target="_blank"
            rel="noreferrer"
            className={styles.sourceLink}
          >
            View Decklist on {deck.source}
          </a>

          {deck.price && (
            <div className={styles.price}>
              Price: ${deck.price.toLocaleString()}
            </div>
          )}

          <CopyButton copyText={copyText}>Copy Decklist</CopyButton>
        </div>

        <div className={styles.mainDeck}>
          {groupedCards ? (
            groupedCards.map((group) => (
              <div key={group.type} className={styles.typeGroup}>
                <div className={styles.typeHeading}>
                  {group.type} ({group.length})
                </div>

                {group.cards.map((card, index) => (
                  <div key={index} className={styles.card}>
                    <CardHover card={card.name}>
                      {card.count > 1 && <>{card.count}x </>}
                      {card.name}
                      {card.ambiguous && <span>*</span>}
                    </CardHover>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <div>
              <div className={styles.loadingIndicator}>
                <LoadingIndicator />
              </div>
              {deck.list.map((card, index) => (
                <div key={index} className={styles.card}>
                  <CardHover card={card.name}>
                    {card.count > 1 && <>{card.count}x </>}
                    {card.name}
                    {card.ambiguous && <span>*</span>}
                  </CardHover>
                </div>
              ))}
            </div>
          )}

          <div className={styles.countsDisclaimer}>
            * Decks from EDHREC have duplicate cards removed, so we estimate
            basic lands from the rest of the deck.{' '}
            {deck.duplicates && (
              <>
                Decks with nonland cards that allow duplicates will likely be
                incorrect.
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Decklist
