import * as styles from './ColorIdentityIcon.module.scss'

import React from 'react'

interface Props {
  colorIdentity?: number
}

const ColorIdentityIcon: React.FC<Props> = (props) => {
  const { colorIdentity } = props

  if (colorIdentity == null) {
    return null
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`${colorIdentity * 32} 0 32 32`}
      className={styles.svg}
    >
      <circle className={styles.colorless} cx="16" cy="16" r="15" />
      <circle className={styles.white} cx="48" cy="16" r="15" />
      <circle className={styles.blue} cx="80" cy="16" r="15" />
      <circle className={styles.black} cx="112" cy="16" r="15" />
      <circle className={styles.red} cx="144" cy="16" r="15" />
      <circle className={styles.green} cx="176" cy="16" r="15" />
      <path
        className={styles.white}
        d="M197.39,26.61A15,15,0,0,1,218.61,5.39"
      />
      <path className={styles.blue} d="M218.61,5.39a15,15,0,0,1-21.22,21.22" />
      <path
        className={styles.white}
        d="M229.39,26.61A15,15,0,0,1,250.61,5.39"
      />
      <path className={styles.black} d="M250.61,5.39a15,15,0,0,1-21.22,21.22" />
      <path
        className={styles.white}
        d="M261.39,26.61A15,15,0,0,1,282.61,5.39"
      />
      <path className={styles.red} d="M282.61,5.39a15,15,0,0,1-21.22,21.22" />
      <path
        className={styles.white}
        d="M293.39,26.61A15,15,0,0,1,314.61,5.39"
      />
      <path className={styles.green} d="M314.61,5.39a15,15,0,0,1-21.22,21.22" />
      <path className={styles.blue} d="M325.39,26.61A15,15,0,0,1,346.61,5.39" />
      <path className={styles.black} d="M346.61,5.39a15,15,0,0,1-21.22,21.22" />
      <path className={styles.blue} d="M357.39,26.61A15,15,0,0,1,378.61,5.39" />
      <path className={styles.red} d="M378.61,5.39a15,15,0,0,1-21.22,21.22" />
      <path className={styles.blue} d="M389.39,26.61A15,15,0,0,1,410.61,5.39" />
      <path className={styles.green} d="M410.61,5.39a15,15,0,0,1-21.22,21.22" />
      <path
        className={styles.black}
        d="M421.39,26.61A15,15,0,0,1,442.61,5.39"
      />
      <path className={styles.red} d="M442.61,5.39a15,15,0,0,1-21.22,21.22" />
      <path
        className={styles.black}
        d="M453.39,26.61A15,15,0,0,1,474.61,5.39"
      />
      <path className={styles.green} d="M474.61,5.39a15,15,0,0,1-21.22,21.22" />
      <path className={styles.red} d="M485.39,26.61A15,15,0,0,1,506.61,5.39" />
      <path className={styles.green} d="M506.61,5.39a15,15,0,0,1-21.22,21.22" />
      <path
        className={styles.blue}
        d="M515,8.5a14.91,14.91,0,0,0-2,7.5h15l7.5-13A15,15,0,0,0,515,8.5Z"
      />
      <path
        className={styles.white}
        d="M528,31a14.85,14.85,0,0,0,7.49-2L528,16H513A15,15,0,0,0,528,31Z"
      />
      <path
        className={styles.black}
        d="M541,8.5A14.9,14.9,0,0,0,535.49,3L528,16l7.5,13A15,15,0,0,0,541,8.5Z"
      />
      <path
        className={styles.blue}
        d="M547,8.5a14.91,14.91,0,0,0-2,7.5h15l7.5-13A15,15,0,0,0,547,8.5Z"
      />
      <path
        className={styles.white}
        d="M560,31a14.85,14.85,0,0,0,7.49-2L560,16H545A15,15,0,0,0,560,31Z"
      />
      <path
        className={styles.red}
        d="M573,8.5A14.9,14.9,0,0,0,567.49,3L560,16l7.5,13A15,15,0,0,0,573,8.5Z"
      />
      <path
        className={styles.blue}
        d="M579,8.5a14.91,14.91,0,0,0-2,7.5h15l7.5-13A15,15,0,0,0,579,8.5Z"
      />
      <path
        className={styles.white}
        d="M592,31a14.85,14.85,0,0,0,7.49-2L592,16H577A15,15,0,0,0,592,31Z"
      />
      <path
        className={styles.green}
        d="M605,8.5A14.9,14.9,0,0,0,599.49,3L592,16l7.5,13A15,15,0,0,0,605,8.5Z"
      />
      <path
        className={styles.black}
        d="M611,8.5a14.91,14.91,0,0,0-2,7.5h15l7.5-13A15,15,0,0,0,611,8.5Z"
      />
      <path
        className={styles.white}
        d="M624,31a14.85,14.85,0,0,0,7.49-2L624,16H609A15,15,0,0,0,624,31Z"
      />
      <path
        className={styles.red}
        d="M637,8.5A14.9,14.9,0,0,0,631.49,3L624,16l7.5,13A15,15,0,0,0,637,8.5Z"
      />
      <path
        className={styles.black}
        d="M643,8.5a14.91,14.91,0,0,0-2,7.5h15l7.5-13A15,15,0,0,0,643,8.5Z"
      />
      <path
        className={styles.white}
        d="M656,31a14.85,14.85,0,0,0,7.49-2L656,16H641A15,15,0,0,0,656,31Z"
      />
      <path
        className={styles.green}
        d="M669,8.5A14.9,14.9,0,0,0,663.49,3L656,16l7.5,13A15,15,0,0,0,669,8.5Z"
      />
      <path
        className={styles.red}
        d="M675,8.5a14.91,14.91,0,0,0-2,7.5h15l7.5-13A15,15,0,0,0,675,8.5Z"
      />
      <path
        className={styles.white}
        d="M688,31a14.85,14.85,0,0,0,7.49-2L688,16H673A15,15,0,0,0,688,31Z"
      />
      <path
        className={styles.green}
        d="M701,8.5A14.9,14.9,0,0,0,695.49,3L688,16l7.5,13A15,15,0,0,0,701,8.5Z"
      />
      <path
        className={styles.black}
        d="M707,8.5a14.91,14.91,0,0,0-2,7.5h15l7.5-13A15,15,0,0,0,707,8.5Z"
      />
      <path
        className={styles.blue}
        d="M720,31a14.85,14.85,0,0,0,7.49-2L720,16H705A15,15,0,0,0,720,31Z"
      />
      <path
        className={styles.red}
        d="M733,8.5A14.9,14.9,0,0,0,727.49,3L720,16l7.5,13A15,15,0,0,0,733,8.5Z"
      />
      <path
        className={styles.black}
        d="M739,8.5a14.91,14.91,0,0,0-2,7.5h15l7.5-13A15,15,0,0,0,739,8.5Z"
      />
      <path
        className={styles.blue}
        d="M752,31a14.85,14.85,0,0,0,7.49-2L752,16H737A15,15,0,0,0,752,31Z"
      />
      <path
        className={styles.green}
        d="M765,8.5A14.9,14.9,0,0,0,759.49,3L752,16l7.5,13A15,15,0,0,0,765,8.5Z"
      />
      <path
        className={styles.red}
        d="M771,8.5a14.91,14.91,0,0,0-2,7.5h15l7.5-13A15,15,0,0,0,771,8.5Z"
      />
      <path
        className={styles.blue}
        d="M784,31a14.85,14.85,0,0,0,7.49-2L784,16H769A15,15,0,0,0,784,31Z"
      />
      <path
        className={styles.green}
        d="M797,8.5A14.9,14.9,0,0,0,791.49,3L784,16l7.5,13A15,15,0,0,0,797,8.5Z"
      />
      <path
        className={styles.red}
        d="M803,8.5a14.91,14.91,0,0,0-2,7.5h15l7.5-13A15,15,0,0,0,803,8.5Z"
      />
      <path
        className={styles.black}
        d="M816,31a14.85,14.85,0,0,0,7.49-2L816,16H801A15,15,0,0,0,816,31Z"
      />
      <path
        className={styles.green}
        d="M829,8.5A14.9,14.9,0,0,0,823.49,3L816,16l7.5,13A15,15,0,0,0,829,8.5Z"
      />
      <path
        className={styles.red}
        d="M837.39,5.39a15,15,0,0,1,21.22,0L848,16Z"
      />
      <path
        className={styles.blue}
        d="M837.39,26.6a15,15,0,0,1,0-21.21L848,16Z"
      />
      <path
        className={styles.white}
        d="M858.61,26.6a15,15,0,0,1-21.22,0L848,16Z"
      />
      <path
        className={styles.black}
        d="M858.61,5.39a15,15,0,0,1,0,21.21L848,16Z"
      />
      <path
        className={styles.green}
        d="M869.39,5.39a15,15,0,0,1,21.22,0L880,16Z"
      />
      <path
        className={styles.blue}
        d="M869.39,26.6a15,15,0,0,1,0-21.21L880,16Z"
      />
      <path
        className={styles.white}
        d="M890.61,26.6a15,15,0,0,1-21.22,0L880,16Z"
      />
      <path
        className={styles.black}
        d="M890.61,5.39a15,15,0,0,1,0,21.21L880,16Z"
      />
      <path
        className={styles.green}
        d="M901.39,5.39a15,15,0,0,1,21.22,0L912,16Z"
      />
      <path
        className={styles.blue}
        d="M901.39,26.6a15,15,0,0,1,0-21.21L912,16Z"
      />
      <path
        className={styles.white}
        d="M922.61,26.6a15,15,0,0,1-21.22,0L912,16Z"
      />
      <path
        className={styles.red}
        d="M922.61,5.39a15,15,0,0,1,0,21.21L912,16Z"
      />
      <path
        className={styles.green}
        d="M933.39,5.39a15,15,0,0,1,21.22,0L944,16Z"
      />
      <path
        className={styles.black}
        d="M933.39,26.6a15,15,0,0,1,0-21.21L944,16Z"
      />
      <path
        className={styles.white}
        d="M954.61,26.6a15,15,0,0,1-21.22,0L944,16Z"
      />
      <path
        className={styles.red}
        d="M954.61,5.39a15,15,0,0,1,0,21.21L944,16Z"
      />
      <path
        className={styles.green}
        d="M965.39,5.39a15,15,0,0,1,21.22,0L976,16Z"
      />
      <path
        className={styles.black}
        d="M965.39,26.6a15,15,0,0,1,0-21.21L976,16Z"
      />
      <path
        className={styles.blue}
        d="M986.61,26.6a15,15,0,0,1-21.22,0L976,16Z"
      />
      <path
        className={styles.red}
        d="M986.61,5.39a15,15,0,0,1,0,21.21L976,16Z"
      />
      <path
        className={styles.white}
        d="M999.18,3.87,1008,16l8.81-12.14A15,15,0,0,0,999.18,3.87Z"
      />
      <path
        className={styles.green}
        d="M993.73,20.63,1008,16,999.18,3.86A15,15,0,0,0,993.73,20.63Z"
      />
      <path
        className={styles.red}
        d="M1008,31V16l-14.27,4.64A15,15,0,0,0,1008,31Z"
      />
      <path
        className={styles.black}
        d="M1022.26,20.63,1008,16V31A15,15,0,0,0,1022.26,20.63Z"
      />
      <path
        className={styles.blue}
        d="M1016.81,3.87,1008,16l14.26,4.64A15,15,0,0,0,1016.81,3.87Z"
      />
    </svg>
  )
}

export default ColorIdentityIcon
