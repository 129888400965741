import { MapProgram } from './buildProgram'

export function bindVertexBuffer(points: number[], programInfo: MapProgram) {
  if (points.length === 0) {
    return
  }

  const { context } = programInfo

  const array = new Float32Array(points)

  const glBuffer = context.createBuffer()
  context.bindBuffer(context.ARRAY_BUFFER, glBuffer)
  context.bufferData(context.ARRAY_BUFFER, array, context.STATIC_DRAW)

  context.enableVertexAttribArray(programInfo.attributes.position)
  context.vertexAttribPointer(
    programInfo.attributes.position,
    2,
    context.FLOAT,
    false,
    12,
    0,
  )

  context.enableVertexAttribArray(programInfo.attributes.texture)
  context.vertexAttribPointer(
    programInfo.attributes.texture,
    1,
    context.FLOAT,
    false,
    12,
    8,
  )
}
