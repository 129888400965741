const specialCases: Record<string, string> = {
  Allies: 'Ally',
  Dwarves: 'Dwarf',
  Elves: 'Elf',
  Foxes: 'Fox',
  Homunculi: 'Homunculus',
  Mercenaries: 'Mercenary',
  Oxen: 'Ox',
  Pegasuses: 'Pegasus',
  Phoenixes: 'Phoenix',
  Sphinxes: 'Sphinx',
  Werewolves: 'Werewolf',
  Wolves: 'Wolf',
}

export const singularTribeName = (pluralName: string) => {
  if (specialCases[pluralName]) {
    return specialCases[pluralName]
  }

  return pluralName.replace(/s$/, '')
}
