import * as styles from './Colors.module.scss'

import React from 'react'

import { DeckTrait, DeckTraitType } from '../data/deckTraits'
import { ColorIdentity } from '../data/colorIdentities'

import ColorTile from './ColorTile'

interface Props {
  colorIdentities: ({
    id: number
    count: number
  } & ColorIdentity)[]
  selectedTrait: DeckTrait | null
  selectTrait(type: DeckTraitType, value: number): void
  isolateTrait(type: DeckTraitType, value: number): void
}

const Colors: React.FC<Props> = (props) => {
  const { colorIdentities, selectedTrait, selectTrait, isolateTrait } = props

  return (
    <div className={styles.container}>
      {colorIdentities.map((item) => (
        <ColorTile
          key={item.id}
          colorIdentity={item}
          {...item}
          selectedTrait={selectedTrait}
          selectTrait={selectTrait}
          isolateTrait={isolateTrait}
        />
      ))}
    </div>
  )
}

export default Colors
