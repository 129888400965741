import { compact } from 'lodash'

import { Item, MapData } from '../data/data'
import { attributesForURL, sources } from '../data/deckSources'

export interface DeckResult {
  type: 'item'
  id: number
  name: string
  source: string
  manaSymbols?: string
  item: Item
}

export function deckSearch(query: string, data: MapData): DeckResult | null {
  const urlAttributes = attributesForURL(query)
  if (urlAttributes == null) {
    return null
  }

  const item = data.allItems.find(
    (item) =>
      item.siteID === urlAttributes.source && item.path === urlAttributes.path,
  )

  if (item == null) {
    return null
  }

  const commander = data.deckTraits?.commanders[item.commanderID]
  const partner = item.partnerID
    ? data.deckTraits?.commanders[item.partnerID]
    : null
  const commanderName = compact([commander?.name, partner?.name]).join(' & ')

  const sourceName = sources[item.siteID].name
  const colorIdentity = data.deckTraits?.colorIdentities?.[item.colorIdentityID]

  return {
    type: 'item',
    id: item.id,
    name: commanderName,
    source: sourceName,
    manaSymbols: colorIdentity?.manaSymbols,
    item,
  }
}
