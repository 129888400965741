// extracted by mini-css-extract-plugin
export var cardImage = "Content-module--cardImage--6c66e";
export var close = "Content-module--close--f950c";
export var container = "Content-module--container--1aa3d";
export var content = "Content-module--content--a41e8";
export var controls = "Content-module--controls--60adb";
export var mapAttributes = "Content-module--mapAttributes--48b38";
export var trait = "Content-module--trait--64400";
export var traitValue = "Content-module--traitValue--4e53b";
export var traits = "Content-module--traits--a938e";
export var traitsHeading = "Content-module--traitsHeading--39e39";
export var traitsIntro = "Content-module--traitsIntro--f7206";