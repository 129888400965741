import * as styles from './Controls.module.scss'

import React, { useState } from 'react'
import classNames from 'classnames'

import Logotype from 'components/layout/Logotype'

import * as Icon from './icons'

import { MapData, Item } from './data/data'
import { DeckTrait, DeckTraitType } from './data/deckTraits'

import Search from './search/Search'

import IsolationDetailsBar from './isolation-details/Bar'
import IsolationDetailsBlock from './isolation-details/Block'

import FAQs from './FAQs'

import DeckDetails from './DeckDetails'
import CommanderDetails from './attribute-details/CommanderDetails'
import ColorIdentityDetails from './attribute-details/ColorIdentityDetails'
import TribeDetails from './attribute-details/TribeDetails'
import ThemeDetails from './attribute-details/ThemeDetails'

interface Props {
  data: MapData | null
  presentTraitsBrowser(): void
  selectedItem: Item | null
  selectAndFocusItem(item: Item): void
  clearSelectedItem(): void
  showClusterInfo: boolean
  setShowClusterInfo(value: boolean): void
  selectedTrait: DeckTrait | null
  highlightedPointCount: number
  selectTrait(type: DeckTraitType, value: number): void
  clearSelectedTrait(): void
  isolatedTrait: DeckTrait | null
  isolateTrait(type: DeckTraitType, value: number): void
  clearIsolatedTrait(): void
}

const Controls: React.FC<Props> = (props) => {
  const {
    data,
    presentTraitsBrowser,
    selectedItem,
    selectAndFocusItem,
    clearSelectedItem,
    showClusterInfo,
    setShowClusterInfo,
    selectedTrait,
    highlightedPointCount,
    selectTrait,
    clearSelectedTrait,
    isolatedTrait,
    isolateTrait,
    clearIsolatedTrait,
  } = props

  const [presentFAQ, setPresentFAQ] = useState(false)

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.primary, {
          [styles.primaryMap]: !(data && isolatedTrait),
          [styles.subMap]: data && isolatedTrait,
        })}
      >
        <h1 className={styles.heading}>
          <Logotype subtitle="Commander Map" />
        </h1>

        <div className={styles.searchWrapper}>
          <div className={styles.searchIcon}>
            <Icon.Search />
          </div>

          <Search
            data={data}
            selectTrait={selectTrait}
            isolateTrait={isolateTrait}
            selectAndFocusItem={selectAndFocusItem}
          />

          <div className={styles.buttons}>
            <button
              onClick={presentTraitsBrowser}
              className={styles.button}
              title="Filter by Colors and Themes"
              disabled={!data}
            >
              <Icon.Categories />
            </button>
            <button
              onClick={() => setPresentFAQ(true)}
              className={styles.button}
              title="About the Map"
            >
              <Icon.Help />
            </button>
          </div>
        </div>

        {data && isolatedTrait && (
          <IsolationDetailsBar
            data={data}
            selectTrait={selectTrait}
            isolatedTrait={isolatedTrait}
            isolateTrait={isolateTrait}
            clearIsolatedTrait={clearIsolatedTrait}
          />
        )}
      </div>

      <div className={styles.details}>
        {data && (
          <>
            {isolatedTrait && (
              <IsolationDetailsBlock
                data={data}
                selectTrait={selectTrait}
                isolatedTrait={isolatedTrait}
                isolateTrait={isolateTrait}
                clearIsolatedTrait={clearIsolatedTrait}
              />
            )}

            {selectedTrait?.type === 'commander' && (
              <div className={styles.selection}>
                <CommanderDetails
                  data={data}
                  commanderID={selectedTrait.value as number}
                  deckCount={highlightedPointCount}
                  selectTrait={selectTrait}
                  clearSelectedTrait={clearSelectedTrait}
                  isolateTrait={isolateTrait}
                />
              </div>
            )}

            {selectedTrait?.type === 'colorIdentity' && (
              <div className={styles.selection}>
                <ColorIdentityDetails
                  data={data}
                  colorIdentityID={selectedTrait.value as number}
                  deckCount={highlightedPointCount}
                  clearSelectedTrait={clearSelectedTrait}
                  isolateTrait={isolateTrait}
                />
              </div>
            )}

            {selectedTrait?.type === 'tribe' && (
              <div className={styles.selection}>
                <TribeDetails
                  data={data}
                  tribeID={selectedTrait.value as number}
                  deckCount={highlightedPointCount}
                  clearSelectedTrait={clearSelectedTrait}
                  isolateTrait={isolateTrait}
                />
              </div>
            )}

            {selectedTrait?.type === 'theme' && (
              <div className={styles.selection}>
                <ThemeDetails
                  data={data}
                  themeID={selectedTrait.value as number}
                  deckCount={highlightedPointCount}
                  clearSelectedTrait={clearSelectedTrait}
                  isolateTrait={isolateTrait}
                />
              </div>
            )}

            {selectedItem && (
              <div className={styles.selection}>
                <DeckDetails
                  data={data}
                  item={selectedItem}
                  clearSelectedItem={clearSelectedItem}
                  showClusterInfo={showClusterInfo}
                  setShowClusterInfo={setShowClusterInfo}
                  selectTrait={selectTrait}
                  isolatedTrait={isolatedTrait}
                  isolateTrait={isolateTrait}
                />
              </div>
            )}
          </>
        )}
      </div>

      <FAQs presented={presentFAQ} dismiss={() => setPresentFAQ(false)} />
    </div>
  )
}

export default React.memo(Controls)
