import React from 'react'

const CategoriesIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width={20}
      height={20}
    >
      <g stroke="#1b2433" fill="none" strokeWidth={1.7}>
        <circle cx="10" cy="4.61" r="1.8" />
        <circle cx="4.33" cy="8.73" r="1.8" />
        <circle cx="6.49" cy="15.39" r="1.8" />
        <circle cx="13.51" cy="15.39" r="1.8" />
        <circle cx="15.67" cy="8.73" r="1.8" />
      </g>
    </svg>
  )
}

export default CategoriesIcon
