import * as styles from './Bar.module.scss'

import React from 'react'

import { MapData } from '../data/data'
import { DeckTrait, DeckTraitType } from '../data/deckTraits'

import Content from './Content'

interface Props {
  data: MapData
  selectTrait(type: DeckTraitType, value: number): void
  isolatedTrait: DeckTrait
  isolateTrait(type: DeckTraitType, value: number): void
  clearIsolatedTrait(): void
}

const IsolationDetailsBar: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <Content {...props} />
    </div>
  )
}

export default React.memo(IsolationDetailsBar)
