export const singleCardThemes = [
  'ad-nauseam',
  'gyruda-companion',
  'jegantha-companion',
  'kaheera-companion',
  'keruga-companion',
  'lurrus-companion',
  'obosh-companion',
  'persistent-petitioners',
  'pod',
  'polymorph',
  'primal-surge',
  'rat-colony',
  'reanimate',
  'relentless-rats',
  'shadowborn-apostles',
  'sunforger',
  'umori-companion',
  'wraths',
  'zirda-companion',
]
