import React from 'react'

const Search: React.FC = () => {
  return (
    <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20">
      <g style={{ fill: 'none', stroke: '#000000' }}>
        <circle cx="8.4" cy="8.4" r="5.5" style={{ strokeWidth: '1.5px' }} />
        <line
          x1="12.6"
          y1="12.6"
          x2="17"
          y2="17"
          style={{ strokeWidth: '1.5px' }}
        />
        <line
          x1="13.9"
          y1="13.9"
          x2="17.6"
          y2="17.6"
          style={{ strokeWidth: '2px' }}
        />
      </g>
    </svg>
  )
}

export default Search
