import * as styles from './PartnerLinks.module.scss'

import React, { useState } from 'react'

import { TextButton } from 'components/controls'

import { DeckTraitType } from '../data/deckTraits'

import * as Icon from '../icons'

interface Props {
  partners: {
    otherPartner: string
    id: number
    name: string
    partners: [string, string]
    count: number | null
  }[]
  isolateTrait(type: DeckTraitType, value: number): void
}

const PartnerLinks: React.FC<Props> = (props) => {
  const { partners, isolateTrait } = props

  const truncate = partners.length > 6

  const [showAll, setShowAll] = useState(false)

  const visibleList = truncate && !showAll ? partners.slice(0, 4) : partners

  return (
    <>
      <h4 className={styles.heading}>Partner Combination Maps</h4>

      {visibleList.map((partner) => (
        <div key={partner.name} className={styles.partner}>
          <button
            onClick={() => isolateTrait('partnerCommanders', partner.id)}
            className={styles.partnerButton}
          >
            {partner.otherPartner}{' '}
            <span>
              <Icon.Isolate />
            </span>
          </button>
          {partner.count && <div>{partner.count.toLocaleString()}</div>}
        </div>
      ))}

      {truncate && !showAll && (
        <TextButton onClick={() => setShowAll(true)}>Show More</TextButton>
      )}
      {truncate && showAll && (
        <TextButton onClick={() => setShowAll(false)}>Show less</TextButton>
      )}
    </>
  )
}

export default PartnerLinks
