// extracted by mini-css-extract-plugin
export var button = "Controls-module--button--d1376";
export var buttons = "Controls-module--buttons--b0e2d";
export var container = "Controls-module--container--f1a6f";
export var details = "Controls-module--details--1e5a3";
export var faqs = "Controls-module--faqs--b55e1";
export var heading = "Controls-module--heading--e0b6a";
export var primary = "Controls-module--primary--998d6";
export var primaryMap = "Controls-module--primaryMap--7766c";
export var searchIcon = "Controls-module--searchIcon--00896";
export var searchWrapper = "Controls-module--searchWrapper--c8267";
export var selection = "Controls-module--selection--4bfff";
export var subMap = "Controls-module--subMap--1002b";