import { useMemo } from 'react'

import { useFetchJSON } from 'utils/useFetch'

import { basePath } from './config'

import { parseCommanderID } from './parseCommanderID'
import { DeckTrait, DeckTraits } from './deckTraits'
import { useIsolationPath } from './isolationPath'

export const useSubmapAttributes = (
  traits: DeckTraits | null,
  selectedTrait: DeckTrait | null,
) => {
  const path = useIsolationPath(traits, selectedTrait)
  const url = useMemo(() => `${basePath}/submaps/${path}/submap.json`, [path])

  const { data } = useFetchJSON<{
    commanderID: [string, number][]
    colorIdentityID: [string, number][]
    themeID: [string, number][]
    tribeID: [string, number][]
    definingCards: [string, number, number][]
    averageDeck: {
      deckID: number
      main: string[]
      commanderID: string
      partnerID: string
      companionID: string
    }
  }>(url)

  const result = useMemo(() => {
    if (!data) {
      return null
    }

    return {
      id: Number,
      commanders:
        data.commanderID != null
          ? data.commanderID.map((row) => ({
              ...parseCommanderID(row[0]),
              percent: row[1],
            }))
          : undefined,
      colorIdentities:
        data.colorIdentityID != null
          ? data.colorIdentityID.map((row) => ({
              id: parseInt(row[0]),
              percent: row[1],
            }))
          : undefined,
      themes:
        data.themeID != null
          ? data.themeID.map((row) => ({
              id: parseInt(row[0]),
              percent: row[1],
            }))
          : undefined,
      tribes:
        data.tribeID != null
          ? data.tribeID.map((row) => ({
              id: parseInt(row[0]),
              percent: row[1],
            }))
          : undefined,
      definingCards: data.definingCards.map((row) => ({
        name: row[0],
        playRate: row[1],
        synergy: row[2],
      })),
      averageDeckID: data.averageDeck.deckID,
    }
  }, [data])

  return result
}
