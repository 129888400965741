import { useMemo } from 'react'
import { kebabCase } from 'lodash'

import { DeckTrait, DeckTraits } from './deckTraits'

/**
 * Returns the path for files needed for the 'isolation mode' of the given
 * trait.
 */
export const useIsolationPath = (
  traits: DeckTraits | null,
  selectedTrait: DeckTrait | null,
) => {
  const path = useMemo(() => {
    if (!traits || !selectedTrait) {
      return null
    }

    const { colorIdentities, commanders, partnerCommanders, themes, tribes } =
      traits

    switch (selectedTrait.type) {
      case 'commander':
        return `commander-partnerID/${kebabCase(
          commanders[selectedTrait.value].name,
        )}`

      case 'partnerCommanders':
        return `commander-partnerID/${kebabCase(
          partnerCommanders?.[selectedTrait.value]?.name,
        )}`

      case 'colorIdentity':
        return `colorIdentityID/${colorIdentities[
          selectedTrait.value
        ].symbol.toLowerCase()}`

      case 'theme':
        return `themeID/${themes[selectedTrait.value].slug}`

      case 'tribe':
        return `tribeID/${tribes[selectedTrait.value].slug}`
    }
  }, [selectedTrait, traits])

  return path
}
