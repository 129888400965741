import * as styles from './ResultRow.module.scss'

import React from 'react'
import classNames from 'classnames'

import ManaCost from 'components/ManaCost'

import { deckTraitDetails } from '../data/deckTraits'

import { SearchResult } from './data'

interface Props {
  result: SearchResult
  index: number
  selectedResultIndex: number
  onSelectResult(result: SearchResult, isolate: boolean): void
  onInputKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void
  setSelectedResultIndex: React.Dispatch<React.SetStateAction<number>>
}

const ResultRow: React.FC<Props> = (props) => {
  const {
    result,
    index,
    selectedResultIndex,
    onSelectResult,
    onInputKeyPress,
    setSelectedResultIndex,
  } = props

  return (
    <div
      className={classNames(styles.container, {
        [styles['selected']]: index === selectedResultIndex,
      })}
      onClick={() => onSelectResult(result, false)}
      onKeyPress={onInputKeyPress}
      onMouseEnter={() => setSelectedResultIndex(index)}
      onFocus={() => setSelectedResultIndex(index)}
      tabIndex={0}
    >
      <div className={styles.title}>{result.name}</div>
      <div className={styles.subtitle}>
        {result.manaSymbols != null && (
          <>
            <ManaCost>{result.manaSymbols}</ManaCost> -{' '}
          </>
        )}

        {result.type === 'item' ? (
          <>Deck from {result.source}</>
        ) : (
          <>
            {['theme', 'tribe'].includes(result.type) && (
              <>{deckTraitDetails[result.type].name} - </>
            )}
            {result.count != null && <>{result.count.toLocaleString()} Decks</>}
          </>
        )}
      </div>
    </div>
  )
}

export default ResultRow
