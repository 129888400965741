import * as styles from './Loadable.module.scss'

import React from 'react'

import LoadingIndicator from 'components/loading/Indicator'

import { MapData, Item } from '../data/data'
import { useDeckList } from '../data/useDeckList'

import Decklist from './Decklist'

interface Props {
  data: MapData
  deck: Item
}

const Loadable: React.FC<Props> = (props) => {
  const { data, deck } = props

  const deckList = useDeckList(data, deck)

  if (deckList == null) {
    return (
      <div className={styles.loadingIndicator}>
        <LoadingIndicator />
      </div>
    )
  }

  return <Decklist deck={deckList} />
}

export default Loadable
