import * as styles from './MapTooltip.module.scss'

import React from 'react'

import { MapData, Item } from './data/data'

import ColorIdentityIcon from './ColorIdentityIcon'

interface Props {
  data: MapData
  item: Item
  position: [number, number]
}

const MapTooltip: React.FC<Props> = (props) => {
  const { data, item, position } = props

  if (data.deckTraits == null) {
    return null
  }

  return (
    <div
      className={styles.container}
      style={{ top: position[1], left: position[0] }}
    >
      <div className={styles.arrow}></div>
      <div className={styles.content}>
        <div>
          <ColorIdentityIcon colorIdentity={item.colorIdentityID} />
        </div>
        <div>
          <div>{data.deckTraits.commanders[item.commanderID]?.name}</div>
          {item.partnerID && (
            <div>& {data.deckTraits.commanders[item.partnerID]?.name}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MapTooltip
