import * as styles from './GroupedColors.module.scss'

import React, { useMemo } from 'react'

import { DeckTrait, DeckTraitType } from '../data/deckTraits'
import { ColorIdentity } from '../data/colorIdentities'

import ColorTile from './ColorTile'
import { groupBy } from 'lodash'

interface Props {
  colorIdentities: ({
    id: number
    count: number
  } & ColorIdentity)[]
  selectedTrait: DeckTrait | null
  selectTrait(type: DeckTraitType, value: number): void
  isolateTrait(type: DeckTraitType, value: number): void
}

const GroupedColors: React.FC<Props> = (props) => {
  const { colorIdentities, selectedTrait, selectTrait, isolateTrait } = props

  const groupedColors = useMemo(() => {
    return groupBy(colorIdentities, (c) => Math.min(c.symbol.length, 4))
  }, [colorIdentities])

  return (
    <div className={styles.container}>
      {['1', '2', '3', '4'].map((group) => (
        <div key={group} className={styles.group}>
          {groupedColors[group].map((item) => (
            <ColorTile
              key={item.id}
              colorIdentity={item}
              {...item}
              selectedTrait={selectedTrait}
              selectTrait={selectTrait}
              isolateTrait={isolateTrait}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export default GroupedColors
