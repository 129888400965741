import * as styles from './ExternalLinks.module.scss'

import React from 'react'

import * as Icon from '../icons'

type Source = 'Scryfall' | 'EDHREC' | 'Cube Cobra'

interface Props {
  links: {
    source: Source
    link: string
    text: string
  }[]
}

const sourceIcons = {
  Scryfall: Icon.ScryfallLogo,
  EDHREC: Icon.EDHRECLogo,
  'Cube Cobra': Icon.CubeCobraLogo,
}

const ExternalLinks: React.FC<Props> = (props) => {
  const { links } = props

  return (
    <div className={styles.container}>
      {links.map((link, index) => {
        const SourceIcon = sourceIcons[link.source]

        return (
          <a
            key={index}
            href={link.link}
            className={styles.link}
            target="_blank"
            rel="noreferrer"
          >
            <SourceIcon /> {link.text}
          </a>
        )
      })}
    </div>
  )
}

export default ExternalLinks
