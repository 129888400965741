import * as styles from './Tooltip.module.scss'

import React, { useState, useCallback } from 'react'
import ReactDOM from 'react-dom'

interface Props {
  children: React.ReactNode
  content: React.ReactNode
}

const Tooltip: React.FC<Props> = (props) => {
  const { children, content } = props

  const [visible, setVisible] = useState(false)
  const [cursorPosition, setCursorPosition] = useState([0, 0])
  const [clientCursorPosition, setClientCursorPosition] = useState([0, 0])

  const onMouseEnter = useCallback(() => {
    setVisible(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setVisible(false)
  }, [])

  const onMouseMove = useCallback((event: React.MouseEvent) => {
    setCursorPosition([event.pageX, event.pageY])
    setClientCursorPosition([event.clientX, event.clientY])
  }, [])

  const positionStyles =
    clientCursorPosition[0] > window.innerWidth - 200
      ? { top: cursorPosition[1], right: window.innerWidth - cursorPosition[0] }
      : { top: cursorPosition[1], left: cursorPosition[0] }

  return (
    <>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        {children}
      </div>

      {visible &&
        ReactDOM.createPortal(
          <div className={styles.tooltip} style={positionStyles}>
            <div className={styles.content}>{content}</div>
          </div>,
          document.getElementById('modal-overlay')!,
        )}
    </>
  )
}

export default Tooltip
