import * as styles from './Info.module.scss'

import React from 'react'

import CardHover from 'components/cards/CardHover'

interface Props {
  type: string
  title: React.ReactNode
  deckCount: number
  cardName?: string | null
  edhrecRank?: number | null
}

const Info: React.FC<Props> = (props) => {
  const { type, title, deckCount, cardName, edhrecRank } = props

  return (
    <div className={styles.container}>
      <div className={styles.type}>{type}</div>
      {cardName ? (
        <div className={styles.title}>
          <CardHover card={cardName}>{title}</CardHover>
        </div>
      ) : (
        <div className={styles.title}>{title}</div>
      )}
      <div className={styles.deckCount}>{deckCount.toLocaleString()} Decks</div>

      {edhrecRank && (
        <div className={styles.rank}>
          EDHREC Rank: {edhrecRank.toLocaleString()}
        </div>
      )}
    </div>
  )
}

export default Info
