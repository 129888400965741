import * as styles from './TraitsBrowser.module.scss'

import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { sortBy } from 'lodash'

import { usePersistentState } from 'utils/usePersistentState'

import { MapData } from '../data/data'
import { DeckTrait, DeckTraitType } from '../data/deckTraits'

import Modal from '../Modal'
import Colors from './Colors'
import GroupedColors from './GroupedColors'
import OptionTile from './OptionTile'

interface Props {
  data: MapData
  visible: boolean
  dismissTraitsBrowser(): void
  selectedTrait: DeckTrait | null
  highlightedPointCount: number
  selectTrait(type: DeckTraitType, value: number): void
  clearSelectedTrait(): void
  isolatedTrait: DeckTrait | null
  isolateTrait(type: DeckTraitType, value: number): void
  clearIsolatedTrait(): void
}

// Options excluding commander and matching the keys in the trait data
type TraitType = 'colorIdentity' | 'theme' | 'tribe'

const TraitOptions: Record<TraitType, { name: string; plural: string }> = {
  colorIdentity: { name: 'Colors', plural: 'colorIdentities' },
  theme: { name: 'Themes', plural: 'themes' },
  tribe: { name: 'Tribes', plural: 'tribes' },
}

// Used to represent any of the traits, losing a bit of type safety for
// simplicity.
interface Trait {
  id: number
  name: string
  slug: string
  image: string
  count: number
  symbol: string
}

const TraitsBrowser: React.FC<Props> = (props) => {
  const {
    data,
    visible,
    dismissTraitsBrowser,
    selectedTrait,
    selectTrait,
    isolateTrait,
  } = props

  const [selectedTraitType, setSelectedTraitType] =
    usePersistentState<TraitType>('edh-map-trait-browser-type', 'colorIdentity')

  const [selectedSortMode, setSelectedSortMode] = usePersistentState<
    'auto' | 'decks'
  >('edh-map-trait-browser-sort', 'auto')

  const sortedItems: Trait[] = useMemo(() => {
    const items =
      data.deckTraits?.[
        TraitOptions[selectedTraitType]?.plural as
          | 'colorIdentities'
          | 'commanders'
          | 'partnerCommanders'
          | 'themes'
          | 'tribes'
      ]

    const filtered = (items as Trait[]).filter((item) => item.count >= 100)

    if (selectedTraitType === 'colorIdentity' && selectedSortMode === 'auto') {
      return filtered
    }

    return sortBy(filtered, (item) =>
      selectedSortMode === 'auto' ? item.name : -item.count,
    )
  }, [data.deckTraits, selectedTraitType, selectedSortMode])

  const selectAndClose = useCallback(
    (type: DeckTraitType, value: number) => {
      selectTrait(type, value)
      dismissTraitsBrowser()
    },
    [dismissTraitsBrowser, selectTrait],
  )

  const isolateAndClose = useCallback(
    (type: DeckTraitType, value: number) => {
      isolateTrait(type, value)
      dismissTraitsBrowser()
    },
    [dismissTraitsBrowser, isolateTrait],
  )

  return (
    <Modal presented={visible} dismiss={dismissTraitsBrowser}>
      <div className={styles.container}>
        <div className={styles.controls}>
          <div className={styles.categories}>
            {Object.keys(TraitOptions).map((traitType: string) => (
              <button
                key={traitType}
                onClick={() => setSelectedTraitType(traitType as TraitType)}
                className={classNames(styles.button, {
                  [styles.selected]: traitType === selectedTraitType,
                })}
              >
                {TraitOptions[traitType as TraitType].name}
              </button>
            ))}
          </div>

          <div className={styles.sort}>
            <div className={styles.optionLabel}>Sort:</div>

            <button
              onClick={() => setSelectedSortMode('auto')}
              className={classNames(styles.button, {
                [styles.selected]: selectedSortMode === 'auto',
              })}
            >
              {selectedTraitType === 'colorIdentity' ? 'Auto' : 'Alphabetical'}
            </button>

            <button
              onClick={() => setSelectedSortMode('decks')}
              className={classNames(styles.button, {
                [styles.selected]: selectedSortMode === 'decks',
              })}
            >
              Decks
            </button>
          </div>
        </div>

        {selectedTraitType === 'colorIdentity' && (
          <>
            {selectedSortMode === 'auto' ? (
              <GroupedColors
                colorIdentities={sortedItems as any[]}
                selectedTrait={selectedTrait}
                selectTrait={selectAndClose}
                isolateTrait={isolateAndClose}
              />
            ) : (
              <Colors
                colorIdentities={sortedItems as any[]}
                selectedTrait={selectedTrait}
                selectTrait={selectAndClose}
                isolateTrait={isolateAndClose}
              />
            )}
          </>
        )}

        {(selectedTraitType === 'tribe' || selectedTraitType === 'theme') && (
          <div className={styles.options}>
            {sortedItems.map((item) => (
              <OptionTile
                key={item.slug}
                traitType={selectedTraitType}
                {...item}
                selectedTrait={selectedTrait}
                selectTrait={selectAndClose}
                isolateTrait={isolateAndClose}
              />
            ))}
          </div>
        )}
      </div>
    </Modal>
  )
}

export default React.memo(TraitsBrowser)
