import * as styles from './InlineIsolateButton.module.scss'

import React from 'react'

import * as Icon from '../icons'

interface Props {
  onClick(): void
}

const InlineIsolateButton: React.FC<Props> = (props) => {
  const { onClick } = props

  return (
    <button
      className={styles.button}
      onClick={onClick}
      title="Isolate Deck Trait"
    >
      <Icon.Isolate />
    </button>
  )
}

export default InlineIsolateButton
