import { useEffect } from 'react'
import { isEmpty } from 'lodash'

import { MapData, Item } from './data'
import { DeckTraitType } from './deckTraits'

import { attributesForURL } from './deckSources'

/**
 * Support linking to particular states of the map including selecting a deck
 * and highlighting a commander.
 *
 * Checks for relevant values in query parameters, removes them and updates the
 * state.
 */
export const useShareLinks = (
  data: MapData | null,
  selectItem: (item: Item) => void,
  selectTrait: (type: DeckTraitType, value: number) => void,
  setShowClusterInfo: (value: boolean) => void,
) => {
  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !data ||
      !data.deckTraits ||
      isEmpty(data.items)
    ) {
      return
    }

    const { deckTraits, items } = data

    const params = new URLSearchParams(window.location.search)

    const deckURL = params.get('deck')
    const highlightCluster = params.get('highlightCluster')
    const commander = params.get('commander')
    const color = params.get('color')
    const tribe = params.get('tribe')
    const theme = params.get('theme')

    if (!deckURL && !commander && !color && !tribe && !theme) {
      return
    }

    if (deckURL) {
      const attributes = attributesForURL(deckURL)

      if (!attributes) {
        throw new Error(
          `Unable to determine attributes for deck url: "${deckURL}"`,
        )
      }

      const item = items.find(
        (row) =>
          row.siteID === attributes.source && row.path === attributes.path,
      )

      if (item) {
        selectItem(item)
      }
      if (highlightCluster === 'true') {
        setShowClusterInfo(true)
      }
    } else if (commander) {
      const id = deckTraits.commanders.findIndex(
        (row) => row.name === commander,
      )
      if (id !== -1) {
        selectTrait('commander', id)
      }
    } else if (color) {
      const id = deckTraits.colorIdentities.findIndex(
        (row) => row.symbol.toLowerCase() === color.toLowerCase(),
      )
      if (id !== -1) {
        selectTrait('colorIdentity', id)
      }
    } else if (tribe) {
      const id = deckTraits.tribes.findIndex((row) => row.slug === tribe)
      if (id !== -1) {
        selectTrait('tribe', id)
      }
    } else if (theme) {
      const id = deckTraits.themes.findIndex((row) => row.slug === theme)
      if (id !== -1) {
        selectTrait('theme', id)
      }
    }

    const url = new URL(window.location.toString())
    url.searchParams.delete('deck')
    url.searchParams.delete('highlightCluster')
    url.searchParams.delete('commander')
    url.searchParams.delete('color')
    url.searchParams.delete('tribe')
    url.searchParams.delete('theme')
    history.replaceState(null, '', url.toString())
  }, [data, selectItem, selectTrait, setShowClusterInfo])
}
