import * as styles from './EDHMap.module.scss'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as d3 from 'd3'
import classNames from 'classnames'

import { expand2dRangeByFraction } from 'components/visualization/utils/ranges'

import { useWindowSize } from 'utils/useWindowSize'
import { useRAFThrottledEffect } from 'utils/useRAFThrottledEffect'

import { Layout } from 'components/maps/shared/Layout'
import { Transform } from 'components/maps/shared/transform'
import { squareExtent } from 'components/maps/shared/squareExtent'

import LoadingOverlay from 'components/loading/Overlay'
import ElementContainer from 'components/utils/ElementContainer'

import { buildProgram } from './rendering/buildProgram'
import { bindTextures } from './rendering/bindTextures'
import { bindVertexBuffer } from './rendering/bindVertexBuffer'
import { usePointSize } from './rendering/pointSize'
import { buildVertexArray } from './rendering/buildVertexArray'
import { draw } from './rendering/draw'

import { useMapPoints, useIsolatedMapPoints, Item } from './data/mapPoints'
import { useIsolatedTrait } from './data/isolatedTrait'
import { DeckTrait, DeckTraitType, useDeckTraits } from './data/deckTraits'
import { useSearchTrees } from './data/searchTree'
import { useShareLinks } from './data/useShareLinks'

import Controls from './Controls'
import Attributions from './Attributions'
import MapTooltip from './MapTooltip'
import TraitsBrowser from './traits-browser/TraitsBrowser'
import LoadingIndicator from './LoadingIndicator'

const EDHMap: React.FC = () => {
  const { width, height } = useWindowSize(800, 800)

  // Loading Traits

  const deckTraits = useDeckTraits()

  // Map Mode

  const [selectedTrait, setSelectedTrait] = useState<DeckTrait | null>(null)

  const [isolatedTrait, setIsolatedTrait] = useIsolatedTrait(deckTraits)

  // Loading Data

  const primaryData = useMapPoints()
  const { items: isolatedTraitData, loading } = useIsolatedMapPoints(
    deckTraits,
    isolatedTrait,
  )

  const items = isolatedTrait ? isolatedTraitData : primaryData

  const data = useMemo(() => {
    if (!items || !deckTraits) {
      return null
    }
    return {
      items,
      allItems: primaryData,
      deckTraits,
    }
  }, [deckTraits, items, primaryData])

  const [zoomTransform, setZoomTransform] = useState(d3.zoomIdentity)

  const [highQuality, setHighQuality] = useState(true)

  const canvasScale = useMemo(() => {
    return typeof window === 'undefined' || !highQuality
      ? 1
      : window.devicePixelRatio
  }, [highQuality])

  useEffect(() => {
    setHighQuality(false)

    const timeout = window.setTimeout(() => {
      setHighQuality(true)
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [zoomTransform])

  const { canvas, programInfo } = useMemo(() => {
    const canvas = document.createElement('canvas')
    canvas.className = styles.canvas

    return { canvas, programInfo: buildProgram(canvas) }
  }, [])

  // Selection

  const [hoveredItem, setHoveredItem] = useState<Item | null>(null)
  const [selectedItem, setSelectedItem] = useState<Item | null>(null)

  const [showClusterInfo, setShowClusterInfo] = useState(false)

  const [showTraitsBrowser, setShowTraitsBrowser] = useState(false)

  const extent = useMemo(
    () => expand2dRangeByFraction(squareExtent(items), 0.1),
    [items],
  )

  const { points, highlightedPointCount } = useMemo(
    () => buildVertexArray(items, selectedItem, showClusterInfo, selectedTrait),
    [items, showClusterInfo, selectedItem, selectedTrait],
  )

  const pointSize = usePointSize(
    Math.min(width, height),
    items.length,
    zoomTransform.k,
    canvasScale,
  )

  // Bind point buffer
  useEffect(() => {
    bindVertexBuffer(points, programInfo)
  }, [points, programInfo])

  const textureSet =
    selectedTrait != null || (showClusterInfo && selectedItem != null)
      ? 'selection'
      : 'standard'

  // Bind Textures
  useEffect(() => {
    bindTextures(programInfo, textureSet)
  }, [programInfo, textureSet])

  // Draw

  useRAFThrottledEffect(() => {
    draw(
      programInfo,
      canvas,
      width,
      height,
      pointSize,
      zoomTransform,
      points,
      extent,
      canvasScale,
    )
  }, [
    width,
    height,
    zoomTransform,
    points,
    extent,
    selectedTrait,
    pointSize,
    canvasScale,
  ])

  // Setup Zoom
  const zoom = useMemo(() => {
    return d3
      .zoom<HTMLCanvasElement, unknown>()
      .scaleExtent([0.5, 10000])
      .on('zoom', (event: { transform: d3.ZoomTransform }) => {
        setZoomTransform(event.transform)
      })
  }, [])

  useEffect(() => {
    d3.select(canvas).call(zoom)
    d3.select(canvas).call(zoom.transform, d3.zoomIdentity)

    return () => {
      d3.select(canvas).on('.zoom', null)
    }
  }, [canvas, zoom])

  // Finding Items in 2d Space
  const itemAtPoint = useSearchTrees(
    primaryData,
    isolatedTraitData,
    isolatedTrait != null,
    width,
    height,
    zoomTransform,
    extent,
  )

  // Events

  const resetZoom = useCallback(() => {
    const { context } = programInfo

    if (context.canvas == null) {
      return
    }

    d3.select(context.canvas as HTMLCanvasElement)
      .transition()
      .duration(250)
      .call(zoom.transform, d3.zoomIdentity)
  }, [programInfo, zoom.transform])

  const selectItem = useCallback((item: Item) => {
    setSelectedItem(item)
  }, [])

  const clearSelectedItem = useCallback(() => {
    setSelectedItem(null)
  }, [])

  const selectTrait = useCallback(
    (type: DeckTraitType, value: number, reset = false) => {
      setSelectedTrait({ type, value })
      setShowClusterInfo(false)
      setSelectedItem(null)

      if (reset) {
        resetZoom()
      }
    },
    [resetZoom, setSelectedTrait],
  )

  const clearSelectedTrait = useCallback(() => {
    setSelectedTrait(null)
  }, [setSelectedTrait])

  const isolateTrait = useCallback(
    (type: DeckTraitType, value: number) => {
      setSelectedTrait(null)
      setSelectedItem(null)
      setShowClusterInfo(false)
      setIsolatedTrait({ type, value })
      resetZoom()
    },
    [resetZoom, setIsolatedTrait, setSelectedTrait],
  )

  const clearIsolatedTrait = useCallback(() => {
    setSelectedTrait(null)
    setIsolatedTrait(null)
    setSelectedItem(null)
    resetZoom()
  }, [resetZoom, setIsolatedTrait, setSelectedTrait])

  const presentTraitsBrowser = useCallback(() => {
    setShowTraitsBrowser(true)
  }, [])

  const dismissTraitsBrowser = useCallback(() => {
    setShowTraitsBrowser(false)
  }, [])

  useShareLinks(data, selectItem, selectTrait, setShowClusterInfo)

  // Map Mouse Events

  useEffect(() => {
    if (highQuality) {
      canvas.onmousemove = (event) => {
        setHoveredItem(itemAtPoint(event))
      }
    } else {
      canvas.onmousemove = null
      setHoveredItem(null)
    }
  }, [canvas, highQuality, itemAtPoint])

  useEffect(() => {
    canvas.onclick = (event) => {
      setSelectedItem(itemAtPoint(event))
      setSelectedTrait(null)
    }
  }, [canvas, itemAtPoint, setSelectedTrait])

  useEffect(() => {
    canvas.onmouseleave = () => setHoveredItem(null)
  }, [canvas])

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'Escape' &&
        (event.target as any)?.tagName?.toLowerCase() !== 'input'
      ) {
        if (showClusterInfo) {
          setShowClusterInfo(false)
        } else {
          setSelectedTrait(null)
          setSelectedItem(null)
        }
      }
    }
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [setSelectedTrait, showClusterInfo])

  useEffect(() => {
    canvas.className = classNames(styles.canvas, {
      [styles.hover]: hoveredItem,
      [styles.loading]: items.length === 0,
    })
  }, [canvas, hoveredItem, items.length])

  const zoomToItem = useCallback(
    (item: { x: number; y: number }) => {
      const sTransform = Transform.withDomainRange(extent, [
        [0, width],
        [0, height],
      ])
        .scaled(Math.min(height / width, 1), Math.min(width / height, 1))
        .translated(
          Math.max((width - height) / 2, 0),
          Math.max((height - width) / 2, 0),
        )

      const point = sTransform.apply([item.x, item.y])

      const newZoom = d3.zoomIdentity
        .translate(width / 2, height / 2)
        .scale(80)
        .translate(-point[0], -point[1])

      d3.select(canvas)
        .transition()
        .duration(1000)
        .call(zoom.transform, newZoom)
    },

    [canvas, extent, height, width, zoom.transform],
  )

  const selectAndFocusItem = useCallback(
    (item: Item) => {
      // If the item is not included in the current map, return to the main map.
      // Zoom to the whole map so the context is more clear.

      const itemInSet = items.find((i) => i.id === item.id)

      if (itemInSet == null) {
        clearIsolatedTrait()
        resetZoom()

        // Not great! But only a little awkward if this gets called out of order.
        setTimeout(() => {
          setSelectedItem(item)
        }, 100)
      } else {
        setSelectedItem(itemInSet)
        zoomToItem(itemInSet)
      }
    },
    [clearIsolatedTrait, items, resetZoom, zoomToItem],
  )

  // Transform

  const transform = Transform.withDomainRange(extent, [
    [0, width],
    [0, height],
  ])
    .scaled(Math.min(height / width, 1), Math.min(width / height, 1))
    .translated(
      Math.max((width - height) / 2, 0),
      Math.max((height - width) / 2, 0),
    )
    .appending(new Transform(zoomTransform.x, zoomTransform.y, zoomTransform.k))

  return (
    <Layout>
      <ElementContainer>{canvas}</ElementContainer>

      <Controls
        data={data}
        presentTraitsBrowser={presentTraitsBrowser}
        selectedItem={selectedItem}
        selectAndFocusItem={selectAndFocusItem}
        clearSelectedItem={clearSelectedItem}
        showClusterInfo={showClusterInfo}
        setShowClusterInfo={setShowClusterInfo}
        selectedTrait={selectedTrait}
        highlightedPointCount={highlightedPointCount}
        selectTrait={selectTrait}
        clearSelectedTrait={clearSelectedTrait}
        isolatedTrait={isolatedTrait}
        isolateTrait={isolateTrait}
        clearIsolatedTrait={clearIsolatedTrait}
      />

      {items.length === 0 && <LoadingOverlay />}

      {data && data.items?.length > 0 && (
        <>
          {selectedItem && (
            <MapTooltip
              data={data}
              item={selectedItem}
              position={transform.apply([selectedItem.x, selectedItem.y])}
            />
          )}

          {hoveredItem && (
            <MapTooltip
              data={data}
              item={hoveredItem}
              position={transform.apply([hoveredItem.x, hoveredItem.y])}
            />
          )}

          <button
            type="button"
            onClick={resetZoom}
            className={styles.resetButton}
            disabled={zoomTransform === d3.zoomIdentity}
          >
            Reset Zoom
          </button>

          <LoadingIndicator loading={loading} />

          <Attributions />

          <TraitsBrowser
            data={data}
            visible={showTraitsBrowser}
            dismissTraitsBrowser={dismissTraitsBrowser}
            selectedTrait={selectedTrait}
            highlightedPointCount={highlightedPointCount}
            selectTrait={selectTrait}
            clearSelectedTrait={clearSelectedTrait}
            isolatedTrait={isolatedTrait}
            isolateTrait={isolateTrait}
            clearIsolatedTrait={clearIsolatedTrait}
          />
        </>
      )}
    </Layout>
  )
}

export default EDHMap
