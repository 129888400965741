import * as styles from './LoadingIndicator.module.scss'

import React from 'react'
import { CSSTransition } from 'react-transition-group'

import Indicator from 'components/loading/Indicator'

interface Props {
  loading: boolean
}

const LoadingIndicator: React.FC<Props> = (props) => {
  const { loading } = props

  return (
    <div className={styles.container}>
      <CSSTransition
        in={loading}
        timeout={200}
        classNames={{
          enterActive: styles.indicatorEnter,
          enterDone: styles.indicatorEnterActive,
          exitActive: styles.indicatorExitActive,
          exitDone: styles.indicatorExit,
        }}
        unmountOnExit
      >
        <div>
          <Indicator />
        </div>
      </CSSTransition>
    </div>
  )
}

export default LoadingIndicator
