/**
 * Parses commander ids where they may contain a commander and partner pair
 * joined by "+".
 */
export const parseCommanderID = (
  value: string,
): { id: number; partnerID?: number } => {
  const ids = value.split('+')
  const result: { id: number; partnerID?: number } = {
    id: parseInt(ids[0]),
  }
  if (ids.length === 2) {
    result.partnerID = parseInt(ids[1])
  }
  return result
}
