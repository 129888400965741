import * as styles from './PartnersTitle.module.scss'

import React from 'react'
import CardHover from 'src/components/cards/CardHover'

interface Props {
  children: string
}

const PartnersTitle: React.FC<Props> = (props) => {
  const { children } = props

  const partners = children.split(' + ')

  return (
    <div className={styles.container}>
      <CardHover card={partners[0]}>{partners[0]}</CardHover> &{' '}
      <CardHover card={partners[1]}>{partners[1]}</CardHover>
    </div>
  )
}

export default PartnersTitle
