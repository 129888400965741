// extracted by mini-css-extract-plugin
export var artistCredit = "DeckDetails-module--artistCredit--19217";
export var artistCreditContainer = "DeckDetails-module--artistCreditContainer--f2e81";
export var clusters = "DeckDetails-module--clusters--42a12";
export var commanderImage = "DeckDetails-module--commanderImage--eb3b8";
export var decklist = "DeckDetails-module--decklist--c50e9";
export var decklistButton = "DeckDetails-module--decklistButton--c99f0";
export var link = "DeckDetails-module--link--dd610";
export var manaCost = "DeckDetails-module--manaCost--d6326";
export var metaData = "DeckDetails-module--metaData--7985d";
export var partnerImage = "DeckDetails-module--partnerImage--17edb";
export var partnerImages = "DeckDetails-module--partnerImages--d55a2";
export var title = "DeckDetails-module--title--f9615";
export var titleButton = "DeckDetails-module--titleButton--eff8b";
export var type = "DeckDetails-module--type--6eadc";