import { useMemo } from 'react'

import { useFetchJSON } from 'utils/useFetch'

import { basePath } from './config'

import { parseCommanderID } from './parseCommanderID'
import { DeckTrait, DeckTraits } from './deckTraits'
import { useIsolationPath } from './isolationPath'

interface ClusterAttributes {
  clusterID: number
  commanderID: [string, number][]
  colorIdentityID: [string, number][]
  themeID: [string, number][]
  tribeID: [string, number][]
  definingCards: [string, number, number][]
  averagePrice: number
  averageDeck: string
}

// TODO: this can likely be somewhat consolidated with 'isolationDetails'.

export const useClusterAttributes = (
  clusterID: number,
  traits: DeckTraits | null,
  isolatedTrait: DeckTrait | null,
) => {
  const isolationPath = useIsolationPath(traits, isolatedTrait)

  const url = useMemo(
    () =>
      isolatedTrait
        ? `${basePath}/submaps/${isolationPath}/edh-submap-clusters.json`
        : `${basePath}/clusters/${clusterID}.json`,
    [clusterID, isolatedTrait, isolationPath],
  )

  const response = useFetchJSON<ClusterAttributes | ClusterAttributes[]>(url)

  const result = useMemo(() => {
    if (!response.data) {
      return null
    }

    const data = Array.isArray(response.data)
      ? response.data.find(
          (row: ClusterAttributes) => row.clusterID === clusterID,
        )
      : response.data

    if (!data) {
      return
    }

    return {
      id: Number,
      commanders:
        data.commanderID != null
          ? data.commanderID.map((row) => ({
              ...parseCommanderID(row[0]),
              percent: row[1],
            }))
          : undefined,
      colorIdentities:
        data.colorIdentityID != null
          ? data.colorIdentityID.map((row) => ({
              id: parseInt(row[0]),
              percent: row[1],
            }))
          : undefined,
      themes:
        data.themeID != null
          ? data.themeID.map((row) => ({
              id: parseInt(row[0]),
              percent: row[1],
            }))
          : undefined,
      tribes:
        data.tribeID != null
          ? data.tribeID.map((row) => ({
              id: parseInt(row[0]),
              percent: row[1],
            }))
          : undefined,
      definingCards: data.definingCards.map((row) => ({
        name: row[0],
        playRate: row[1],
        synergy: row[2],
      })),
      averagePrice: data.averagePrice,
      averageDeckID: parseInt(data.averageDeck),
    }
  }, [clusterID, response.data])

  return result
}
