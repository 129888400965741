import { useMemo } from 'react'
import { clamp } from 'lodash'

export const usePointSize = (
  windowSize: number,
  pointCount: number,
  zoomScale: number,
  canvasScale: number,
) => {
  const size = useMemo(() => {
    const base = 20 / Math.pow(pointCount, 0.25)

    const scaleWeight = 0.1

    const size = base * (1 - scaleWeight) + base * zoomScale * scaleWeight
    const windowScaled = clamp(size * clamp(windowSize / 1300, 0.5, 2), 1, 16)

    return windowScaled * canvasScale
  }, [canvasScale, pointCount, windowSize, zoomScale])

  return size
}
