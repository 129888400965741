export const colorColors: Record<string, string> = {
  c: '#AFAFAF',
  w: '#DDD485',
  u: '#2366F9',
  b: '#2C0D59',
  r: '#EF3706',
  g: '#049900',
}

const colorNames: Record<string, string> = {
  c: 'Colorless',
  w: 'White',
  u: 'Blue',
  b: 'Black',
  r: 'Red',
  g: 'Green',
}

export interface ColorIdentity {
  colors: any[]
  symbol: string
  manaSymbols: string
  name: string
  edhrecSlug: string
  searchAliases: string
}

export const colorIdentities = [
  {
    symbol: 'C',
    name: 'Colorless',
    edhrecSlug: 'colorless',
    searchAliases: '',
  },

  {
    symbol: 'W',
    name: 'White',
    edhrecSlug: 'w',
    searchAliases: '',
  },
  {
    symbol: 'U',
    name: 'Blue',
    edhrecSlug: 'u',
    searchAliases: '',
  },
  {
    symbol: 'B',
    name: 'Black',
    edhrecSlug: 'b',
    searchAliases: '',
  },
  {
    symbol: 'R',
    name: 'Red',
    edhrecSlug: 'r',
    searchAliases: '',
  },
  {
    symbol: 'G',
    name: 'Green',
    edhrecSlug: 'g',
    searchAliases: '',
  },

  {
    symbol: 'WU',
    name: 'Azorius',
    edhrecSlug: 'wu',
    searchAliases: '',
  },
  {
    symbol: 'WB',
    name: 'Orzhov',
    edhrecSlug: 'wb',
    searchAliases: 'Silverquill',
  },
  {
    symbol: 'WR',
    name: 'Boros',
    edhrecSlug: 'rw',
    searchAliases: 'Lorehold',
  },
  {
    symbol: 'WG',
    name: 'Selesnya',
    edhrecSlug: 'gw',
    searchAliases: '',
  },
  {
    symbol: 'UB',
    name: 'Dimir',
    edhrecSlug: 'ub',
    searchAliases: '',
  },
  {
    symbol: 'UR',
    name: 'Izzet',
    edhrecSlug: 'ur',
    searchAliases: 'Prismari',
  },
  {
    symbol: 'UG',
    name: 'Simic',
    edhrecSlug: 'gu',
    searchAliases: 'Quandrix',
  },
  {
    symbol: 'BR',
    name: 'Rakdos',
    edhrecSlug: 'br',
    searchAliases: '',
  },
  {
    symbol: 'BG',
    name: 'Golgari',
    edhrecSlug: 'bg',
    searchAliases: 'Witherbloom',
  },
  {
    symbol: 'RG',
    name: 'Gruul',
    edhrecSlug: 'rg',
    searchAliases: '',
  },

  {
    symbol: 'WUB',
    name: 'Esper',
    edhrecSlug: 'wub',
    searchAliases: 'Obscura',
  },
  {
    symbol: 'WUR',
    name: 'Jeskai',
    edhrecSlug: 'urw',
    searchAliases: 'Raugrin',
  },
  {
    symbol: 'WUG',
    name: 'Bant',
    edhrecSlug: 'gwu',
    searchAliases: 'Brokers',
  },
  {
    symbol: 'WBR',
    name: 'Mardu',
    edhrecSlug: 'rwb',
    searchAliases: 'Savai',
  },
  {
    symbol: 'WBG',
    name: 'Abzan',
    edhrecSlug: 'wbg',
    searchAliases: 'Indatha',
  },
  {
    symbol: 'WRG',
    name: 'Naya',
    edhrecSlug: 'rgw',
    searchAliases: 'Cabaretti',
  },
  {
    symbol: 'UBR',
    name: 'Grixis',
    edhrecSlug: 'ubr',
    searchAliases: 'Maestros',
  },
  {
    symbol: 'UBG',
    name: 'Sultai',
    edhrecSlug: 'bgu',
    searchAliases: 'Zagoth',
  },
  {
    symbol: 'URG',
    name: 'Temur',
    edhrecSlug: 'gur',
    searchAliases: 'Ketria',
  },
  {
    symbol: 'BRG',
    name: 'Jund',
    edhrecSlug: 'brg',
    searchAliases: 'Riveteers',
  },

  {
    symbol: 'WUBR',
    name: 'Artifice',
    edhrecSlug: 'wubr',
    searchAliases: 'Yore-Tiller',
  },
  {
    symbol: 'WUBG',
    name: 'Growth',
    edhrecSlug: 'gwub',
    searchAliases: 'Witch-Maw',
  },
  {
    symbol: 'WURG',
    name: 'Altruism',
    edhrecSlug: 'rgwu',
    searchAliases: 'Ink-Treader',
  },
  {
    symbol: 'WBRG',
    name: 'Aggression',
    edhrecSlug: 'brgw',
    searchAliases: 'Dune-Brood',
  },
  {
    symbol: 'UBRG',
    name: 'Chaos',
    edhrecSlug: 'ubrg',
    searchAliases: 'Glint-Eye Nephilim',
  },

  {
    symbol: 'WUBRG',
    name: '5 Color',
    edhrecSlug: 'wubrg',
    searchAliases: 'wubrg five rainbow',
  },
].map((identity) => {
  const colorSymbols = identity.symbol.toLowerCase().split('')

  return {
    ...identity,
    colors: colorSymbols.map((letter) => colorColors[letter]),
    manaSymbols: identity.symbol
      .split('')
      .map((s) => `{${s.toLowerCase()}}`)
      .join(''),
    searchAliases: `${identity.searchAliases} ${colorSymbols.map(
      (s) => colorNames[s],
    )}`,
  }
})

export const colorIdentitySymbols = colorIdentities.map((ci) => ci.symbol)
