import { Transform } from 'components/maps/shared/transform'
import { clipSpace } from 'components/maps/shared/clipSpace'

import { MapProgram } from './buildProgram'

export function draw(
  programInfo: MapProgram,
  canvas: HTMLCanvasElement,
  width: number,
  height: number,
  pointSize: number,
  zoomTransform: d3.ZoomTransform,
  points: number[],
  extent: [[number, number], [number, number]],
  canvasScale: number,
) {
  const { context } = programInfo

  canvas.width = width * canvasScale
  canvas.height = height * canvasScale

  context.viewport(0, 0, width * canvasScale, height * canvasScale)

  const bounds: [[number, number], [number, number]] = [
    [0, width],
    [0, height],
  ]

  const transform = Transform.withDomainRange(extent, bounds)
    .scaled(Math.min(height / width, 1), Math.min(width / height, 1))
    .translated(
      Math.max((width - height) / 2, 0),
      Math.max((height - width) / 2, 0),
    )
    .appending(new Transform(zoomTransform.x, zoomTransform.y, zoomTransform.k))
    .rescaled(bounds, clipSpace)

  context.uniform4f(programInfo.uniforms.transform, ...transform.components())

  context.uniform1f(programInfo.uniforms.pointSize, pointSize)

  context.enable(context.BLEND)
  context.blendFunc(context.SRC_ALPHA, context.ONE_MINUS_SRC_ALPHA)

  context.clearColor(1, 1, 1, 1)
  context.clear(context.COLOR_BUFFER_BIT)

  if (points.length !== 0) {
    context.drawArrays(context.POINTS, 0, points.length / 3)
  }
}
