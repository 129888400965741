import * as styles from './ThemeDetails.module.scss'

import React, { useMemo } from 'react'

import * as scryfall from 'utils/scryfall'

import CardImage from 'components/cards/CardImage'
import CardHover from 'components/cards/CardHover'

import { MapData } from '../data/data'
import { DeckTraitType } from '../data/deckTraits'
import { singleCardThemes } from '../data/singleCardThemes'

import Card from '../info-elements/Card'
import ContentSection from '../info-elements/ContentSection'
import CloseButton from '../info-elements/CloseButton'
import IsolateButton from '../info-elements/IsolateButton'
import ExternalLinks from '../info-elements/ExternalLinks'
import ArtistCredit from '../info-elements/ArtistCredit'

import Info from './Info'

interface Props {
  data: MapData
  themeID: number
  deckCount: number
  clearSelectedTrait(): void
  isolateTrait(type: DeckTraitType, value: number): void
}

const ThemeDetails: React.FC<Props> = (props) => {
  const { data, themeID, deckCount, clearSelectedTrait, isolateTrait } = props

  const theme = useMemo(
    () => (data?.deckTraits?.themes ? data.deckTraits.themes[themeID] : null),
    [themeID, data?.deckTraits?.themes],
  )

  const image = useMemo(
    () =>
      theme
        ? scryfall.imageURL({ cardName: theme.image, version: 'art_crop' })
        : null,
    [theme],
  )

  if (!theme) {
    return null
  }

  const hasKeyCard = singleCardThemes.includes(theme.slug)

  return (
    <Card>
      <CloseButton onClick={clearSelectedTrait} />

      <div className={styles.images}>
        <img className={styles.image} src={image ?? ''} key={image} />
      </div>

      <ContentSection>
        <div className={styles.content}>
          <div>
            <Info
              type="Theme"
              title={theme.name}
              deckCount={deckCount}
              cardName={hasKeyCard ? theme.image : null}
            />
          </div>

          {hasKeyCard && (
            <div>
              <CardHover card={theme.image}>
                <CardImage>{theme.image}</CardImage>
              </CardHover>
            </div>
          )}
        </div>

        <IsolateButton onClick={() => isolateTrait('theme', themeID)}>
          View Map of {theme.name} Decks
        </IsolateButton>
      </ContentSection>

      <ContentSection>
        <div className={styles.artistCredit}>
          <ArtistCredit cardName={theme.image} showCardName />
        </div>

        <ExternalLinks
          links={[
            {
              source: 'EDHREC',
              link: `https://edhrec.com/themes/${theme.slug}`,
              text: 'View Theme on EDHREC',
            },
          ]}
        />
      </ContentSection>
    </Card>
  )
}

export default ThemeDetails
