import * as styles from './ClusterInfo.module.scss'

import React, { useMemo, useState } from 'react'

import { TextButton } from 'components/controls'

import { MapData, Item } from './data/data'
import { DeckTrait, DeckTraitType } from './data/deckTraits'
import { useClusterAttributes } from './data/clusterAttributes'

import ContentSection from './info-elements/ContentSection'

import Decklist from './deck-list/Loadable'
import Modal from './Modal'
import Traits from './Traits'

interface Props {
  data: MapData
  item: Item
  selectTrait(type: DeckTraitType, value: number): void
  isolatedTrait: DeckTrait | null
  isolateTrait(type: DeckTraitType, value: number): void
}

const ClusterInfo: React.FC<Props> = (props) => {
  const { data, item, selectTrait, isolatedTrait, isolateTrait } = props

  const clusterInfo = useClusterAttributes(
    item.clusterID,
    data.deckTraits,
    isolatedTrait,
  )

  const [showDecklist, setShowDecklist] = useState(false)

  const averageDeck = useMemo(
    () => (clusterInfo ? data.allItems[clusterInfo.averageDeckID] : null),
    [clusterInfo, data.allItems],
  )

  if (clusterInfo == null) {
    return null
  }

  return (
    <ContentSection>
      <div>
        <strong>Average Price: </strong> $
        {clusterInfo.averagePrice.toLocaleString()}
      </div>

      <div className={styles.listLink}>
        <TextButton onClick={() => setShowDecklist(true)}>
          View representative decklist
        </TextButton>
      </div>

      <Traits
        data={data}
        traits={clusterInfo}
        selectTrait={selectTrait}
        isolateTrait={isolateTrait}
      />

      {averageDeck && (
        <Modal presented={showDecklist} dismiss={() => setShowDecklist(false)}>
          <div className={styles.decklist}>
            <Decklist data={data} deck={averageDeck} />
          </div>
        </Modal>
      )}
    </ContentSection>
  )
}

export default ClusterInfo
