import * as styles from './CloseButton.module.scss'

import React from 'react'

import * as Icon from '../icons'

interface Props {
  onClick(): void
}

const CloseButton: React.FC<Props> = (props) => {
  const { onClick } = props

  return (
    <button className={styles.button} onClick={onClick}>
      <Icon.X color="#ffffff" />
    </button>
  )
}

export default CloseButton
