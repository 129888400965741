// extracted by mini-css-extract-plugin
export var card = "Decklist-module--card--09094";
export var columns = "Decklist-module--columns--fee76";
export var commander = "Decklist-module--commander--d37fe";
export var countsDisclaimer = "Decklist-module--countsDisclaimer--e614f";
export var heading = "Decklist-module--heading--9385d";
export var loadingIndicator = "Decklist-module--loadingIndicator--3a419";
export var mainDeck = "Decklist-module--mainDeck--12094";
export var meta = "Decklist-module--meta--ed30b";
export var price = "Decklist-module--price--6723d";
export var sourceLink = "Decklist-module--sourceLink--eb908";
export var typeGroup = "Decklist-module--typeGroup--d4057";
export var typeHeading = "Decklist-module--typeHeading--647b9";