import React from 'react'

const HelpIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width={20}
      height={20}
    >
      <g fill="#1b2433">
        <path d="M8.13,12.57v-3.29c1.15-.04,2.05-.27,2.71-.69,.66-.42,1-1.07,1-1.96v-.3c0-.71-.21-1.23-.63-1.57-.42-.34-.97-.51-1.67-.51-.74,0-1.33,.21-1.78,.63-.45,.42-.76,.94-.93,1.58l-2.02-.81c.13-.44,.32-.87,.58-1.28,.26-.42,.59-.78,.98-1.09,.39-.31,.85-.56,1.39-.75,.54-.19,1.16-.29,1.87-.29s1.35,.1,1.92,.3c.57,.2,1.06,.48,1.47,.84,.41,.36,.72,.8,.94,1.31,.22,.52,.33,1.09,.33,1.71s-.11,1.2-.34,1.7c-.23,.5-.52,.92-.89,1.27-.37,.35-.79,.64-1.26,.87-.47,.23-.96,.38-1.45,.47v1.87h-2.23Zm1.17,4.94c-.65,0-1.11-.13-1.38-.4-.27-.27-.4-.61-.4-1.02v-.51c0-.41,.13-.75,.4-1.02,.27-.27,.73-.4,1.38-.4s1.11,.13,1.38,.4,.4,.61,.4,1.02v.51c0,.41-.13,.75-.4,1.02-.27,.27-.73,.4-1.38,.4Z" />
      </g>
    </svg>
  )
}

export default HelpIcon
