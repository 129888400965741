import * as styles from './ExpandingSection.module.scss'

import React from 'react'

import * as Icon from '../icons'

interface Props {
  expanded: boolean
  onChange(value: boolean): void
  label: string
  children: React.ReactNode
}

const ExpandingSection: React.FC<Props> = (props) => {
  const { expanded, onChange, label, children } = props

  return (
    <>
      <button
        onClick={() => onChange(!expanded)}
        className={styles.toggleButton}
      >
        {expanded ? (
          <>
            <div>Hide {label}</div>
            <Icon.UpArrow />
          </>
        ) : (
          <>
            <div>Show {label}</div>
            <Icon.DownArrow />
          </>
        )}
      </button>

      {expanded && <>{children}</>}
    </>
  )
}

export default ExpandingSection
